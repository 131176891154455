import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import successMarker from "../../../assets/images/icons/marker/redmark.svg";
import darkMarker from "../../../assets/images/icons/marker/greenmark.svg";
import yellowMarker from "../../../assets/images/icons/marker/yellowmarker.svg";

import { useDispatch } from 'react-redux';
import { openCustomDrawer } from '../../../redux/slices/modalSlice';
import { fetchAllDriversLocations } from '../../../redux/slices/hyperdrive/drivers';
import { useSelector } from 'react-redux';
import { formatTimestampToDateView } from 'utilities/common';
import { InfoOutlined } from '@mui/icons-material';
function getRandomCoordinate(base, offset) {
  return base + (Math.random() * 2 - 1) * offset;
}
function getRandomStatus() {
  // Randomly return "1" or "2"
  return Math.random() > 0.5 ? "1" : "2";
}


function generateRandomLocations(numLocations) {
  const baseLat = 33.877491;
  const baseLng = -117.566096;
  const offset = 0.02; // Offset for random lat/long generation

  const locations = [];

  for (let i = 0; i < numLocations; i++) {
    const randomLat = getRandomCoordinate(baseLat, offset);
    const randomLng = getRandomCoordinate(baseLng, offset);

    const location = {
      "lat": randomLat,
      "lng": randomLng,
      "status": getRandomStatus(),
      "tasks": [
        {
          "failureDetails": {},
          "attachments": {
            "photoUrl": []
          },
          "companyAnnoucementIds": [],
          "_id": `66dacfc0c5ef3d2b666bd3b1`, // Unique ID for each task
          // "_id": `66d064eb5c78548965d0ba78${i}`,
          "taskType": "deliveryTask",
          "taskTag": "high",
          "taskAssignmentMode": "manual",
          "fleetId": "66c789d20d318e94f8b62a5c",
          "taskDescription": "descriptions",
          "recipientDetails": {
            "name": "Mathew",
            "phone": "+19876545790",
            "notes": "please reach before time if possible"
          },
          "orderDetails": "Please don't ring the bell",
          "address": {
            "address1": "945 White Ranch Circle, Corona, CA, USA",
            "address2": "",
            "city": "Corona",
            "state": "CA",
            "zipcode": "92881",
            "country": "US",
            "latitude": randomLat.toString(),
            "longitude": randomLng.toString(),
            "notes": "urgent"
          },
          "taskStartTime": "2024-08-30T11:30:00.000Z",
          "taskEndTime": "2024-08-30T12:20:00.000Z",
          "driverAppRequirements": {
            "customerSignature": true,
            "deliveryPhoto": true,
            "addNotes": true
          },
          "multipleFleetIds": [],
          "items": [
            {
              "productId": "668ef41501bdfc49d503e31b",
              "quantity": 1,
              "productName": "Biscotti",
              "productSku": "H31BPRO2",
              "categoryName": "Premium",
              "brandName": "Flora & Flame"
            }
          ],
          "isActive": true,
          "taskStatus": "not_started",
          "createdBy": "60e6e5697e92567d11ada1b2",
          "createdDate": "2024-08-29T12:09:15.799Z",
          "updatedDate": "2024-08-29T12:09:15.799Z",
          "taskDisplayId": 1031,
          "taskArchived": true,
          "expectedArrivalTime": "2024-08-30T11:30:00.000Z",
          "fleet": {
            "locationData": {
              "latitude": randomLat,
              "longitude": randomLng
            },
            "_id": "66c789d20d318e94f8b62a5c",
            "fleetOnDutyStatus": false,
            "fleetName": "ttt",
            "fleetEmail": "shgfdfgadeleted@GFhfhh.com"
          }
        }
      ],
      "fleetId": "66c789d20d318e94f8b62a5c"
    };

    locations.push(location);
  }

  return locations;
}

const MapDrive = ({ dataPassComponent }) => {
  const dispatch = useDispatch()
  const { driversLocation = {} } = useSelector(state => state.drivers)
  
  const mapRef = useRef(null);
  const mapKey = process.env.REACT_APP_MAP_KEY;
  const mapInstance = useRef(null);
  const [taskDetailFetch, setTaskDetailFetch] = useState("")
  const selectedPopup = useRef()
  const memoizeDriversLocation = useMemo(() => {
    //  return [
    //     { lat: 33.8255728, lng: -117.5634377, label: 'Los Angeles', status: '1' },
    //     { lat: 34.0246, lng: -118.2428, label: 'Santa Monica', status: '3' },
    //   ]
    const locations = driversLocation?.data?.map((location => {
      return {
        lat: location?.locationData?.latitude || 33.8254462,
        lng: location?.locationData?.longitude || -117.5636722,
        status: location?.fleetStatus ? '1' : '2',
        tasks: location?.tasks,
        fleetId: location?.fleetId
      }
    })) || []
    return generateRandomLocations(20);
  }, [driversLocation])
  console.log("driversLocation?.data",driversLocation?.data);
  

  useEffect(() => {
    if (!window.H) return;
    const platform = new H.service.Platform({
      apikey: mapKey,
    });
    const rasterTileService = platform.getRasterTileService({
      queryParams: {
        'style': 'logistics.night',
        'features': 'vehicle_restrictions:active_and_inactive,pois:disabled',
        'size': 512
      }
    });
    const rasterTileProvider = new H.service.rasterTile.Provider(rasterTileService);
    const rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider);

    const defaultLayers = platform.createDefaultLayers();
    mapInstance.current = new H.Map(
      mapRef.current,
      rasterTileLayer,
      {
        zoom: 13,
        center: { lat: 33.8255728, lng: -117.5634377 },
        pixelRatio: window.devicePixelRatio || 1
      }
    );
    new H.mapevents.Behavior(new H.mapevents.MapEvents(mapInstance.current));
    const ui = H.ui.UI.createDefault(mapInstance.current, defaultLayers);
    const overviewMap = new H.ui.Overview(defaultLayers.raster.satellite.map, {
      alignment: H.ui.LayoutAlignment.LEFT_BOTTOM,
      zoomDelta: 6,
      scaleX: 5,
      scaleY: 6,
    });
    ui.addControl('overview', overviewMap);

  }, [mapKey]);

  const showDriverLocations = useCallback(() => {
    if (!window.H) return;
    const customIconSuccess = new H.map.Icon(successMarker);
    const customIconDark = new H.map.Icon(darkMarker);
    const customIconYellow = new H.map.Icon(yellowMarker);
    if (memoizeDriversLocation?.length === 0) return;
    memoizeDriversLocation?.forEach(markerData => {
      console.log("markerData", markerData)
      const marker = new H.map.Marker(
        { lat: markerData?.lat, lng: markerData?.lng },
        { icon: markerData?.status === '1' ? customIconSuccess : markerData?.status === '3' ? customIconYellow : customIconDark }
      );
      marker.addEventListener('tap', () => {
        showInfoMessage(markerData, markerData?.tasks);
      });
      marker.addEventListener('pointerenter', () => {
        console.log("ender")
      });
      marker.addEventListener('pointerenter', () => {
        console.log("ender")
      });
      marker.setData(markerData.label);
      mapInstance.current.addObject(marker);
    });

    // Prepare data for the heat map
    const heatMapData = memoizeDriversLocation.map(marker => ({
      lat: marker.lat,
      lng: marker.lng,
      value: marker.status === '1' ? 1 : 0.5,
    }));

    // Create a provider for the heat map
    const heatmapProvider = new H.data.heatmap.Provider({
      colors: new H.data.heatmap.Colors({
        '0': 'blue',
        '0.5': 'yellow',
        '1': 'red'
      }, true),
      opacity: 0.1,
      assumeValues: true
    });

    // Add the data to the heatmap provider
    heatmapProvider.addData(heatMapData);

    // Add a layer for the heat map provider to the map
    mapInstance.current.addLayer(new H.map.layer.TileLayer(heatmapProvider));
  }, [memoizeDriversLocation])

  useEffect(() => {
    const resize = () => {
      if (mapInstance.current) {
        mapInstance.current.getViewPort().resize()
      }
    }
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, [])

  useEffect(() => {
    showDriverLocations()
  }, [memoizeDriversLocation])

  const showInfoMessage = (markerData, tasks = []) => {
    if (selectedPopup.current) {
      selectedPopup.current?.removeBubbleHanlder()
    }
    const platform = new H.service.Platform({
      apikey: mapKey,
    });
    const defaultLayers = platform.createDefaultLayers();
    const ui = H.ui.UI.createDefault(mapInstance.current, defaultLayers);
    selectedPopup.current = ui;
    const generateTableContent = (tasks) => {
      return tasks?.map(task => `
        <tr data-destination="${task?._id}" class="clickable-row">
          <td><Typography class='w-100 ellipsis-one text-white-700 text-12'>${task.address?.address1}</Typography></td>
          <td><Typography class='w-100 ellipsis-one text-white-700 text-12'>${task.recipientDetails?.name}</Typography></td>
          <td><Typography class='w-100 ellipsis-one text-white-700 text-12'>${task.fleet?.fleetName}</Typography></td>
          <td><Typography class='w-100 d-inline-block text-white-700 text-12'>${formatTimestampToDateView(task?.createdDate)}</Typography></td>
        </tr>
      `).join('');
    };

    // Custom content for the info bubble
    const content = `
      <div class='tasks-modal'>
        <Typography variant='h6' class='w-100 d-flex font-w-600 text-black-400 text-14 text-left px-3 py-2'>${tasks?.length || 0}  Tasks</Typography>
        <Table class='hw-table' border="0" cellspacing="0" cellpadding="0">
          <thead>
            <tr>
              <th> 
                <Typography class='w-100 d-inline-block text-black-400 text-12 text-left box-sizing-border'>Destination</Typography>
              </th>
              <th>
                <Typography class='w-100 d-inline-block text-black-400 text-12 text-left'>Recipient</Typography>
              </th>
              <th>
                <Typography class='w-100 d-inline-block text-black-400 text-12 text-left'>Driver</Typography>
              </th>
              <th>
                <Typography class='w-100 d-inline-block text-black-400 text-12 text-left'>Date/Time</Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            ${generateTableContent(tasks)}
          </tbody>
        </Table>
      </div>`;

    const infoBubble = new H.ui.InfoBubble(markerData, {
      content
    });
    ui.addBubble(infoBubble);
      selectedPopup.current.removeBubbleHanlder = () => {
        ui.removeBubble(infoBubble)
      }
    // setTimeout(() => {
      const clickableRows = document.querySelectorAll('.clickable-row');
      clickableRows.forEach(row => {
        row.addEventListener('click', (event) => {
          const destination = event.currentTarget.getAttribute('data-destination');
          console.log('Destination clicked:', destination);
          setTaskDetailFetch(destination)
        });
      });
    // }, 100);
  };

  useEffect(() => {
    if (taskDetailFetch) {
      dispatch(openCustomDrawer({
        drawer: 'TASK_DETAIL',
        drawerData: taskDetailFetch
      }))
    }
  }, [taskDetailFetch])

  useEffect(() => {
    dispatch(fetchAllDriversLocations({ startDate: '2024-08-25', endDate: '2024-09-06' })).unwrap().then((res) => {
      console.log("res", res)
    }).catch(err => {
      console.log("err", err)
    })
  }, [])


  return (
    <>
      <div className='hd-left-wrapper'>
        <div ref={mapRef} style={{ width: '100%', height: '100%' }}></div>
      </div>

    </>
  );
};
export default MapDrive

