import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from 'react-places-autocomplete';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export const findType = (addr, type) => {
    let comp = addr.find((item) => item.types.indexOf(type) >= 0);
    return comp ? comp.short_name : null;
}


export default function GoogleSuggestion({ addressChangeHandler = () => { }, addressValue = '', onClearAddress = {}, googleAddressErrorHandler }) {
    const [address, setAddress] = useState('');


    useEffect(() => {
        if (addressValue) {
            setAddress(addressValue)
        }
    }, [addressValue])


    const handleSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        let addrObj = results && results[0] || {}
        let addr = addrObj && addrObj.address_components || []
        let addrAddress = addrObj.formatted_address || ''
        let addrCity = findType(addr, "locality") || findType(addr, "sublocality") || findType(addr, "neighborhood");
        let addrState = findType(addr, "administrative_area_level_1");
        let addrZipcode = findType(addr, "postal_code");
        let country = findType(addr, "country");
        let locationObjData = {
            lat: latLng.lat,
            long: latLng.lng,
            value: addrZipcode,
            address: addrAddress,
            country,
            deliveryAddress: {
                address: addrAddress,
                city: addrCity,
                state: addrState,
                zipcode: addrZipcode,
                lat: latLng.lat,
                long: latLng.lng,
            }
        }
        setAddress(value)
        addressChangeHandler(locationObjData?.deliveryAddress)
    };

    const handleChange = (value) => {
        if (value?.length === 0) {
            googleAddressErrorHandler(true)
        }else{
            googleAddressErrorHandler(false)
        }
        setAddress(value);
    };
    return (
        <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            searchOptions={{
                componentRestrictions: { country: "us" },
            }}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <TextField
                        fullWidth
                        multiline
                        rows={1}
                        {...getInputProps({
                            placeholder: 'Set Your location',
                            className: 'location-search-input',
                            InputProps: {
                                endAdornment: <InputAdornment position="start">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                            setAddress("")
                                            onClearAddress({
                                                address: '',
                                                city: '',
                                                state: '',
                                                zipcode: '',
                                                lat: '',
                                                lng: ''
                                            })
                                        }}
                                        edge="end"
                                    >
                                        {address?.length ? <CancelOutlinedIcon /> : ""}
                                    </IconButton>
                                </InputAdornment>,
                            }
                        })}
                    />
                    <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style
                                    })}
                                    key={suggestion}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    )
}
