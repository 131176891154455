import React, { useEffect, useState } from 'react'
import { Box, Typography, Button, FormControl, FormControlLabel, Menu, RadioGroup, Radio } from "@mui/material";
import { useDispatch } from "react-redux";
import VerifiedIcon from '@mui/icons-material/Verified';
import AssignIcon from "common/customIcons/assign";
import ReassignDriverIcon from "common/customIcons/reassigndriver";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RecipientIcon from 'common/customIcons/recipient';
import RedirectIcon from 'common/customIcons/redirect';
import MapPinIcon from 'common/customIcons/mappin';
import TimeIcon from 'common/customIcons/time';
import UserIcon from 'common/customIcons/user';
import ActivityLogIcon from 'common/customIcons/activitylog';
import CheckIcon from 'common/customIcons/check';
import ViewRouteIcon from 'common/customIcons/viewroute';
import { useSelector } from 'react-redux';
import { fetchTaskDetails } from '../../../redux/slices/hyperdrive/drivers';
import TaskLogs from './TaskLogs';
import TaskRoute from './TaskRoute';
import MapDriveListDrawer from '../createTask/MapDriveListDrawer';
import { valid } from 'chroma-js';

const TaskDetails = () => {
    const dispatch = useDispatch()
    const { drawerData } = useSelector(state => state?.modal)
    const { taskDetails } = useSelector(state => state?.drivers)
    const [activityLog, setActivityLog] = useState(false)
    const [routeView, setRouteView] = useState(false)
    const [showManualList, setShowManualList] = useState(false);
    const [formData, setFormData] = useState({
        fleetId: "",
        taskAssignmentMode: "auto",
    })

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        dispatch(fetchTaskDetails(drawerData))
    }, [])

    const handleShowLogs = (data) => {
        if (data === "activity") {
            setActivityLog(true)
            setRouteView(false)

        }
        else if (data === "route") {
            setRouteView(true)
            setActivityLog(false)
        }
        else {
            setRouteView(false)
            setActivityLog(false)
        }
    }

    function handleDataFromChild() {
        setRouteView(false)
        setActivityLog(false)
        setShowManualList(false)
    }

    const startTaskHandler = (event) => {
        const { name, value } = event.target;
        if (name === 'taskAssignmentMode') {
            if (value === "manual") {
                setShowManualList(true)
            }
            else {
                setShowManualList(false)
            }
        }
        setFormData(prevData => {
            const newData = {
                ...prevData,
                [name]: value
            }
            return newData;
        })
    }

    const handleFleetValueChange = (value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            fleetId: value
        }));
    };


    return (
        <>
            <Box className="cus-wrapper-drawer">

                <Box display="flex" alignItems="center" justifyContent="space-between" p={2} style={{ borderBottom: "1px solid  #1E1E24" }} className="drawer-head-sec">
                    <Typography className="text-14 text-white-700">Task {taskDetails?.data?.taskDisplayId}</Typography>
                    <Typography className="d-flex align-item-center gap-1 text-green-100 text-12">
                        <VerifiedIcon style={{ width: "1.125rem", height: "1.125rem" }} />
                        Delivered
                    </Typography>
                </Box>

                <Box className="drawer-scroll-sec">
                    <Box display="flex" alignItems="center" flexDirection="column" px={2} py={4} style={{ borderBottom: "1px solid  #1E1E24" }} gap={4} className="drawer-listing">
                        <div className='w-100 d-flex jc-s-btwn'>
                            <div className='d-flex gap-2'>
                                <div className='d-flex'>
                                    <AssignIcon />
                                </div>
                                <div className='d-flex flex-column'>
                                    <Typography className='text-black-400 text-12 line-height-normal mb-1'>Assigned to</Typography>
                                    <Typography className='text-white-700 text-16 line-height-normal' fontWeight="semibold">James Hall</Typography>
                                    <Typography className='text-white-700 text-16 line-height-normal' fontWeight="semibold">(904) 335-2403</Typography>
                                </div>
                            </div>
                            <div className='d-flex'>
                                <Button
                                    id=""
                                    aria-controls={open ? '' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    className='d-flex gap-2 text-white-900'
                                >
                                    <ReassignDriverIcon />
                                    <Typography fontWeight="semibold" className='text-12 text-white-900 text-transform-none'>Reassign Driver</Typography>
                                    <KeyboardArrowDownIcon />
                                </Button>
                                <Menu
                                    id=""
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{ className: "hd-dropdown reassign-driver-dropdown" }}
                                >
                                    <FormControl>
                                        <RadioGroup
                                            // value={formData?.taskAssignmentMode}
                                            aria-labelledby=""
                                            defaultValue="auto"
                                            name="taskAssignmentMode"
                                            className='radio-colors'
                                            onChange={(e) => startTaskHandler(e)}
                                        >
                                            <FormControlLabel className='field-off-duty' value="manual" control={<Radio />} label="Manual" />
                                            <FormControlLabel className='field-idle' value="auto" control={<Radio />} label="Automatic" />
                                        </RadioGroup>
                                    </FormControl>
                                </Menu>
                            </div>
                        </div>
                        <div className='w-100 d-flex jc-s-btwn'>
                            <div className='d-flex gap-2'>
                                <div className='d-flex'>
                                    <RecipientIcon />
                                </div>
                                <div className='d-flex flex-column'>
                                    <Typography className='text-black-400 text-12 line-height-normal mb-1'>Recipient</Typography>
                                    <Typography className='text-white-700 text-16 line-height-normal' fontWeight="semibold">{taskDetails?.data?.recipientDetails?.name}</Typography>
                                    <Typography className='text-white-700 text-16 line-height-normal' fontWeight="semibold">{taskDetails?.data?.recipientDetails?.phone}</Typography>
                                </div>
                            </div>
                        </div>
                    </Box>

                    <Box display="flex" alignItems="center" flexDirection="column" px={2} py={4} gap={4} className="drawer-listing">
                        <div className='w-100 d-flex flex-column'>
                            <Typography className='text-white-700 text-24 mb-3' fontWeight="semibold">Task Details</Typography>

                            <Box display="flex" alignItems="center" flexDirection="column" gap={4}>
                                <div className='w-100 d-flex jc-s-btwn'>
                                    <div className='d-flex gap-2'>
                                        <div className='d-flex'>
                                            <MapPinIcon />
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <Typography className='text-black-400 text-12 line-height-normal mb-1'>Destination</Typography>
                                            <Typography className='text-white-700 text-16 line-height-normal' fontWeight="semibold">{taskDetails?.data?.address?.address1}</Typography>
                                        </div>
                                    </div>
                                    <div className='d-flex align-item-top'>
                                        <Button
                                            id=""
                                            aria-controls={open ? '' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            // onClick={handleClick}
                                            className='d-flex border-none p-0 min-width-auto h-auto'
                                        >
                                            <RedirectIcon />
                                        </Button>
                                    </div>
                                </div>

                                <div className='w-100 d-flex jc-s-btwn'>
                                    <div className='d-flex gap-2'>
                                        <div className='d-flex'>
                                            <TimeIcon />
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <Typography className='text-black-400 text-12 line-height-normal mb-1'>Task Scheduled</Typography>
                                            <Typography className='text-white-700 text-16 line-height-normal' fontWeight="semibold">Before 10:02</Typography>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-100 d-flex jc-s-btwn'>
                                    <div className='d-flex gap-2'>
                                        <div className='d-flex'>
                                            <TimeIcon />
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <Typography className='text-black-400 text-12 line-height-normal mb-1'>Service Time</Typography>
                                            <Typography className='text-white-700 text-16 line-height-normal' fontWeight="semibold">7 minutes</Typography>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-100 d-flex jc-s-btwn'>
                                    <div className='d-flex gap-2'>
                                        <div className='d-flex'>
                                            <UserIcon />
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <Typography className='text-black-400 text-12 line-height-normal mb-1'>Created By</Typography>
                                            <Typography className='text-white-700 text-16 line-height-normal' fontWeight="semibold">Sandy Mandon</Typography>
                                        </div>
                                    </div>
                                </div>
                            </Box>

                        </div>
                    </Box>

                    <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection="column" p={2} gap={1}>
                        <div className='w-100 d-flex jc-s-btwn gap2'>
                            <Typography className='text-white-700 text-14 line-height-normal' fontWeight="semibold">Drop Off</Typography>
                            <Typography className='text-white-700 text-16 line-height-normal' fontWeight="semibold">Discreet curb side</Typography>
                        </div>
                        <div className='w-100 d-flex jc-s-btwn gap2'>
                            <Typography className='text-white-700 text-14 line-height-normal' fontWeight="semibold">Instructions</Typography>
                            <Typography className='text-white-700 text-16 line-height-normal' fontWeight="semibold">Inside the Cooper in Smith ave</Typography>
                        </div>
                        <div className='w-100 d-flex jc-s-btwn gap2'>
                            <Typography className='text-white-700 text-14 line-height-normal' fontWeight="semibold">Total after discount</Typography>
                            <Typography className='text-white-700 text-16 line-height-normal' fontWeight="semibold">$55.55</Typography>
                        </div>
                        <div className='w-100 d-flex jc-s-btwn gap2'>
                            <Typography className='text-white-700 text-14 line-height-normal' fontWeight="semibold">Payment type</Typography>
                            <Typography className='text-white-700 text-16 line-height-normal' fontWeight="semibold">Credit</Typography>
                        </div>
                    </Box>

                </Box>

                <Box display="flex" alignItems="center" justifyContent="space-between" p={2} gap={2} style={{ borderTop: "1px solid  #1E1E24" }} className="drawer-bottom-btns">
                    <Button onClick={() => handleShowLogs("activity")} variant="contained" color="primary" className='d-flex align-item-center gap-2'>
                        <ActivityLogIcon />
                        Activity Log
                    </Button>
                    <Button onClick={() => handleShowLogs("route")} variant="contained" color="primary" className='d-flex align-item-center gap-2 purple-btn'>
                        <ViewRouteIcon />
                        View Route
                    </Button>
                    <Button variant="contained" color="primary" className='d-flex align-item-center gap-2 green-btn'>
                        <CheckIcon />
                        Done
                    </Button>
                </Box>
            </Box>

            {activityLog &&
                <TaskLogs sendDataToParent={handleDataFromChild} />
            }
            {routeView &&
                <TaskRoute sendDataToParent={handleDataFromChild} />
            }
            {showManualList &&
                <MapDriveListDrawer sendDataToParent={handleDataFromChild} onValueChange={handleFleetValueChange} />
            }
        </>
    )
}

export default TaskDetails