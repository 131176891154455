import { Typography, Box, Table } from '@mui/material';
import React from 'react'
import EastIcon from '@mui/icons-material/East';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

const TaskLogs = ({ sendDataToParent = () => { } }) => {
    const handleDriverDetails = () => {
        sendDataToParent(null);
    }
    return (
        <div className="next-drawer activity-log-drawer">
            <Box p={2} className='d-flex align-item-center jc-s-btwn gap-2 drawer-head'>
                <div className='d-flex flex-row gap-3'>
                    <Typography variant='h6' className='text-14 text-white-700'>Activity Log</Typography>
                </div>
                <Typography style={{ cursor: "pointer" }} onClick={() => handleDriverDetails()} className='d-flex align-item-center gap-1 text-14 text-white-700'>
                    Close <EastIcon />
                </Typography>
            </Box>
            <Box className='drawer-scroll-sec'>
                <Box p={2}>
                    <Table class='hw-table' border="0" cellspacing="0" cellpadding="0">
                        <thead>
                            <tr>
                                <th>
                                    <Typography class='w-100 d-inline-block text-black-400 text-12 text-left box-sizing-border'>Name</Typography>
                                </th>
                                <th>
                                    <Typography class='w-100 d-inline-block text-black-400 text-12 text-left'>Date</Typography>
                                </th>
                                <th>
                                    <Typography class='w-100 d-inline-block text-black-400 text-12 text-left'>Edit Tasks</Typography>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td><Typography class='w-100 ellipsis-one text-white-700 text-12'>John Doe</Typography></td>
                                <td><Typography class='w-100 d-flex align-item-center gap-1 text-white-700 text-12'><AccessTimeFilledIcon style={{ width: "15px", height: "15px" }} /> 1d</Typography></td>
                                <td>
                                    <Typography class='w-100 ellipsis-one text-white-700 text-12 d-flex align-item-center gap-2'>
                                        Reassign Driver - John <EastIcon /> before 10:30
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Box>
            </Box>
        </div >
    )
}

export default TaskLogs
