import React, { useMemo, useState } from 'react'

// @mui material components
import CommonLayout from "layouts/DashboardLayout";
import { Box, Card, Paper } from "@mui/material";

import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import MapDriver from "./MapDrive"
import MapDriveList from 'components/hyperdrive/mapDriverList';
import MenuTabs from 'components/hyperdrive/menuTabs';
import FiltersDriverTasks from 'components/hyperdrive/filtersDriverTasks';
import Table from '../Table';
import { useSearchParams } from 'react-router-dom';
import DriverAnalytics from '../DriverAnalytics';
import DriverAnalyticsDetails from '../DriverAnalytics/DriverDetails';
import Analyze from './Analyze';
import RouteAnalytics from './RouteAnalytics';
import ArchiveTask from '../Table/Archive';

function Tracking() {
  const [searchParams] = useSearchParams();
  const [dataPassComponent, setDataPassComponent] = useState({})
  const fullRoleTab = useMemo(() => searchParams.get('roleTab'), [searchParams]);

  const tabValue = useMemo(() => {
    if (fullRoleTab) {
      const segments = fullRoleTab.split('/');
      return segments[0];
    }
    return 'map';
  }, [fullRoleTab]);
  const isDriverDetails = fullRoleTab?.includes('detailsDriver');
  const driverId = fullRoleTab ? fullRoleTab.split('/').pop().split('=').pop() : null;

  // State and handlers
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [anchorTask, setAnchorTask] = React.useState(null);
  const opentask = Boolean(anchorTask);

  const getDataFromChild = (dateRange, formDataStatus) => {
    const filterData = { ...dateRange?.[0], ...formDataStatus }
    setDataPassComponent(filterData)
  }

  return (
    <CommonLayout>
      <Box className="hd-wrapper">
        <TabContext value={tabValue}>
          <MenuTabs />
          {(fullRoleTab === "table" || fullRoleTab === "archive" || fullRoleTab === "map") &&
            <FiltersDriverTasks
              sendDataToChild={getDataFromChild}
              setAnchorEl={setAnchorEl}
              setAnchorTask={setAnchorTask}
              anchorEl={anchorEl}
              anchorTask={anchorTask}
              opentask={opentask}
              open={open}
            />
          }
          <TabPanel value="map" className='p-0 show-filter-sec'>
            <Card component={Paper} style={{ flexDirection: "row" }} sx={{ borderRadius: 0 }} className='content-height'>
              <MapDriver dataPassComponent={dataPassComponent} />
              <div className='hd-right-wrapper'>
                <div className='hd-drivers-listing'>
                  <MapDriveList />
                </div>
              </div>
            </Card>
          </TabPanel>
          <TabPanel value={"table"} className='p-0 show-filter-sec'>
            <div className='content-height'>
              <Table dataPassComponent={dataPassComponent} />
            </div>
          </TabPanel>
          <TabPanel value={"archive"} className='p-0 content-height'>
            <ArchiveTask dataPassComponent={dataPassComponent} />
          </TabPanel>
          <TabPanel value="analyze" className='p-0 content-height'>
            <Analyze />
          </TabPanel>
          <TabPanel value="route_analytics" className='p-0 content-height'>
            <RouteAnalytics />
          </TabPanel>
          <TabPanel value="driver_analytics" className='p-0 content-height'>
            {isDriverDetails ? <DriverAnalyticsDetails driverId={driverId} /> : <DriverAnalytics />}
          </TabPanel>
        </TabContext>
      </Box>
    </CommonLayout>
  );
}

export default Tracking;
