import dayjs from 'dayjs';
import moment from 'moment';
import CryptoJS from "crypto-js";
import { HYPERDRIVE } from 'utilities/constants';
import { HYPERWOLF } from 'utilities/constants';
import { HEMP } from 'utilities/constants';
export function getData(label) {
    if (typeof window !== "undefined") {
        // Client-side-only code
        const data = window && window?.localStorage?.getItem(label);
        return data
    }
}

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


export function setData(label, data) {
    if (typeof window !== "undefined") {
        // Client-side-only code
        if (window && window?.localStorage)
            localStorage?.setItem(label, JSON.stringify(data));
    }
}

export function removeData(label) {
    if (typeof window !== "undefined") {
        // Client-side-only code
        if (window && window.localStorage)
            localStorage.removeItem(label);
    }
}

export const getQueryString = (query = {}) => {
    const queryString = Object.keys(query)
        .reduce((acc, key) => {
            if (acc !== '') {
                acc += '&';
            }
            return acc + `${key}=${encodeURIComponent(query[key])}`;
        }, '');
    return queryString
}

export const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    const hours = date.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const displayHours = hours % 12 || 12;
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${month}/${day}/${year} ${displayHours}:${minutes} ${ampm}`;
};

export const formattedDate = (dateString) => {
    // Parse the original date string to a Date object
    const date = new Date(dateString);
    
    // Extract the year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    
    // Format the date as YYYY-MM-DD
    return `${year}-${month}-${day}`;
};

export const convertToTimestamp = (dateString) => {
    const date = new Date(dateString); // Create a Date object from the string
    const timestamp = date.getTime();
    return timestamp
}

export const convertToTimestampWithMoment = (dateString) => {
    const date = new Date(dateString); // Create a Date object from the string
    const timestamp = moment(dateString).valueOf()
    return timestamp
}



export const formatTimestampToDate = (timestamp) => {
    // Assuming the timestamp is in milliseconds
    const formattedDate = dayjs(timestamp).format('DD-MM-YYYY HH:mm');
    return formattedDate;
};

export const formatTimestampToDateView = (timestamp) => {
    // Assuming the timestamp is in milliseconds
    const formattedDate = dayjs(timestamp).format('MMM DD, YYYY hh:mm a');
    return formattedDate;
};


export const formatTimestampToWithoutTime = (timestamp) => {
    // Assuming the timestamp is in milliseconds
    const formattedDate = dayjs(timestamp).format('MMM DD, YYYY');
    return formattedDate;
};

export const formatTimestampToTime = (timestamp) => {
    // Assuming the timestamp is in milliseconds
    const formattedTime = dayjs(timestamp).format('HH:mm');
    return formattedTime;
};

export const formatTimestampTo12HourTime = (timestamp) => {
    // Assuming the timestamp is in milliseconds
    const formattedTime = dayjs(timestamp).format('hh:mm a');
    return formattedTime;
};


export function isEmptyObject(obj = {}) {
    // Using Object.keys() to get an array of object keys and checking its length
    return Object.keys(obj).length === 0;
}

export function isUnder21(dateOfBirth) {
    const dob = dayjs(dateOfBirth);
    const currentDate = dayjs();
    const age = currentDate.diff(dob, 'year');

    return age < 21;
}

export function formatAddress(deliveryAddress = {}) {
    console.log("deliveryAddress",deliveryAddress)
    // const { street, city, state, country } = address;
    const { address, city, state, zipcode, zipCode, apartment = '' } = deliveryAddress;

    // Using template literals to concatenate the address elements
    // const formattedAddress = `${street}, ${city}, ${state}, ${zipcode}`;
    const formattedAddress = apartment ? `${apartment}, ${address}, ${city}, ${state}, ${zipcode || zipCode}` : `${address}, ${city}, ${state}, ${zipcode || zipCode}`;
    return formattedAddress;
}

export function getPercentage(offeredPrice, salePrice) {
    return parseFloat(((offeredPrice - salePrice) / offeredPrice) * 100).toFixed(0)
}


export function isLoggedIn(userType) {
    let user = JSON.parse(getData("login-user-info")) || {};
    if (user.access_token) return { isAuthenticated: true, user }
    else return { isAuthenticated: false }
}
export function logOut(userType) {
    return new Promise((res, rej) => {
        localStorage.removeItem(userType);
        res(true);
    });
}

// awaiting_payment, awaiting_shipment, shipped, on_hold, cancelled, pending_fulfillment
export const orderStatus = [
    {
        key: "awaiting_shipment",
        value: 'In Progress',
    },
    {
        key: 'shipped',
        value: 'On the way',
    },
    {
        key: 'cancelled',
        value: 'Cancelled',
    },
    {
        key: 'awaiting_payment',
        value: 'Payment Pending',
    },
    {
        key: 'on_hold',
        value: 'On Hold'
    },

]

export const orderDetailStatus = [
    {
        key: "awaiting_shipment",
        value: 'In Progress',
    },
    {
        key: 'shipped',
        value: 'Shipped',
    },
    {
        key: 'cancelled',
        value: 'Cancelled',
    },
    {
        key: 'awaiting_payment',
        value: 'Payment Pending',
    },
    {
        key: 'delivered',
        value: 'Delivered',
    },

]

export const returnRequestStatus = [
    {
        key: "rejected",
        value: 'Rejected',
    },
    {
        key: 'approved',
        value: 'Approved',
    },

]

export const sortsBy = [
    {
        key: 'Today',
        value: 'today'
    },
    {
        key: '1 Week',
        value: 'week'
    },
    {
        key: '1 Month',
        value: 'month'
    },
    {
        key: '3 Months',
        value: 'threeMonths'
    },
    {
        key: 'All Time',
        value: 'All Time'
    }
]

export const orderFilterTabs = [
    {
        key: 'All Orders',
        value: ''
    },
    {
        key: 'In-progress',
        value: 'awaiting_shipment'
    },
    {
        key: 'Shipped',
        value: 'shipped'
    },
    {
        key: 'Completed',
        value: 'delivered'
    },
    {
        key: 'Cancelled',
        value: 'cancelled'
    },
    {
        key: 'Warranty',
        value: 'warranty'
    },
]

export const driverFilterTabs = [
    {
        key: 'Drivers',
        value: ''
    },
    {
        key: 'Breaks',
        value: 'breaks'
    },
    {
        key: 'Approvals',
        value: 'approvals'
    }
]

export const strainsTabs = [
    {
        key: 'Strain Homepage',
        value: 'main_strains'
    },
    {
        key: 'Strains',
        value: 'strains'
    }
]

export const brandTabs = [
    {
        key: 'Brand Homepage',
        value: 'main_brands'
    },
    {
        key: 'Brands',
        value: 'brands'
    }
]


export const roleAndPermissionTabs = [
    {
        key: 'Users',
        value: 'users'
    },
    {
        key: 'Roles',
        value: 'roles'
    }
]

export function addCommas(number) {
    let [integerPart, decimalPart] = number?.toString().split('.');

    // Add commas to the integer part
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Combine the integer and decimal parts with commas
    const formattedNumber = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;

    return formattedNumber;
}

export function addCommasWithToFixed(number) {
    if (!number) return ''
    number = Number(number)?.toFixed(2);
    // if (typeof number !== 'number') {
    //     throw new Error('Input must be a number.');
    // }

    // Convert the number to a string and split the decimal part
    let [integerPart, decimalPart] = number?.toString().split('.');

    // Add commas to the integer part
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Combine the integer and decimal parts with commas
    const formattedNumber = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;

    return formattedNumber;
}

export function capitalizeText(text) {
    if (!text) return ''
    if (typeof text !== 'string') {
        throw new Error('Input should be a string');
    }

    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function addSpaceBeforeCapital(text) {
    return text.replace(/([A-Z])/g, ' $1').trim();
}


export function validatePassword(password) {
    // Minimum length of 8 characters
    const minLength = 8;

    // Require at least one uppercase letter, one lowercase letter, one digit, and one special character
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /\d/;
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

    // Check length
    if (password.length < minLength) {
        return false;
    }

    // Check for required character types
    if (!uppercaseRegex.test(password) || !lowercaseRegex.test(password) || !digitRegex.test(password) || !specialCharRegex.test(password)) {
        return false;
    }

    // If all checks pass, the password is considered valid
    return true;
}


export function formatUsPhoneNumber(phoneNumber) {
    // Remove any non-digit characters
    const cleanedNumber = phoneNumber.replace(/\D/g, '');

    // Format the phone number
    const formattedNumber = `(${cleanedNumber.slice(0, 3)}) ${cleanedNumber.slice(3, 6)}-${cleanedNumber.slice(6)}`;

    return formattedNumber;
}

export function debounce(func, delay) {
    let timeoutId;

    return function (...args) {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}


export function separateUnitAndNumber(text) {
    // Define a regular expression to match a number followed by optional whitespace, and then an optional percentage sign
    const regex = /^(\d+(\.\d+)?)(\s*%)?(\s*([a-zA-Z]+))?$/;

    // Use the regular expression to match the text
    const match = text.match(regex);

    if (match) {
        // Extract the value and unit from the matched groups
        const value = parseFloat(match[1]);
        const unit = match[5] || null; // Use null if no unit is present

        // Return an object containing the value and unit
        return { value, unit };
    } else {
        // Return null if the text doesn't match the expected pattern
        return null;
    }
}

export function isNumber(value) {
    return /^\d+$/.test(value);
}

export function isNumberWithDot(value) {
    return /^\d+.$/.test(value);
}


export function isValidNumber(input) {
    const regex = /^\d+(\.\d+)?$/;
    return regex.test(+input);
}

export function isValidFileExtension(file, validExtensions) {
    const fileName = file?.name
    const fileExtension = fileName.split('.').pop();
    return validExtensions.includes(fileExtension.toLowerCase());
}

export function generatePromoCodes(n) {
    const length = 7;
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    const promoCodes = [];

    for (let i = 0; i < n; i++) {
        let promoCode = '';
        for (let j = 0; j < length; j++) {
            promoCode += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        promoCodes.push(promoCode);
    }

    return promoCodes;
}


export const RolesAndPermissions = [
    {
        "Super Admin": {
            "Permissions": [
                "All"
            ]
        },
        "Shop Admin": {
            "Permissions": [
                "Products",
                "Orders",
                "Inventory",
                "Members",
                "Regions",
                "POS",
                "Banners",
                "Promotions"
            ]
        },
        "Shop Manager": {
            "Permissions": [
                "Products",
                "Orders",
                "Inventory",
                "Members",
                "Regions",
                "POS",
                "Banners",
                "Promotions"
            ]
        },
        "Inventory Manager": {
            "Permissions": [
                "Products",
                "Inventory",
                "Sub-categories",
                "Categories"
            ]
        },
        "Sales Associate": {
            "Permissions": [
                "Orders",
                "Members",
                "POS"
            ]
        },
        "Customer Support": {
            "Permissions": [
                "Orders",
                "Members",
                "POS"
            ]
        },
        "Marketing Manager": {
            "Permissions": [
                "Strains",
                "Terpenes (Cannabinoids)",
                "Categories",
                "Subcategories",
                "Brands - all",
                "FAQ - all",
                "Products - all",
                "Banners - all"
            ]
        },
        "Developer": {
            "Permissions": [
                "Admin"
            ]
        }
    }
]

export const downloadFile = (fileLink, fileName) => {
    return new Promise((resolve, reject) => {
        if (fileLink) {
            let link = document.createElement('a');
            link.href = fileLink;
            link.download = `${fileName}.csv`;
            link.click();
            return resolve(true)
        }
        else {
            return reject(false)
        }
    })

}

export const parseDate = (dateString) => {
    const format = 'DD-MM-YY HH:mm:ss';
    const parsed = dayjs(dateString, format);
    return parsed.isValid() ? parsed.format() : 'Invalid date';
};

export function generateEncryptedAuthKey() {
    let encrypted = CryptoJS.AES.encrypt(process.env.REACT_APP_API_AUTH_KEY, process.env.REACT_APP_AUTH_PHRASE_KEY, {
        mode: CryptoJS.mode.ECB,
    }).toString();
    return encrypted
}


export function getImageExtension(filename) {
    // Check if the filename contains a period
    if (filename.lastIndexOf(".") !== -1) {
        // Extract the substring from the last period to the end of the filename
        return filename.substring(filename.lastIndexOf(".") + 1).toLowerCase();
    } else {
        // If no period is found, return an empty string
        return "";
    }
}

export const getLocaUserInfo = () => {
    if (typeof window !== "undefined") {
        // Client-side-only code
        const data = window && window?.localStorage?.getItem('login-user-info');
        if (data) {
            return JSON.parse(data);
        }
        return {}
    }
}

export const getMarginToSale = (unitPrice, margin) => {
    const salePrice = +unitPrice * (100 + +margin) / 100
    return salePrice;
}

export const getSaleToMargin = (unitPrice, salePrice) => {

    const margin = 100 * (+salePrice - +unitPrice) / +unitPrice
    return margin;
}


export const calculateHundredYearsFromTimeStamp = (timestamp) => {
    const date = new Date(timestamp);
    date.setFullYear(date.getFullYear() + 150)
    return date;
};

export const cannabinoidsTabs = [
    {
        key: 'Home',
        value: 'main_cannabinoid'
    },
    {
        key: 'Cannabinoids',
        value: 'cannabinoids'
    }
]

export const adminsOptions = [
    // {
    //     key: 'Hyperdrive',
    //     value: HYPERDRIVE
    // },
    {
        key: "Hyperwolf",
        value: HYPERWOLF
    },
    {
        key: 'Hemp',
        value: HEMP
    }
]

export const hyperdriveTaskOptions = [
    // {
    //     key: 'Pickup Task',
    //     value: 'pick_up_task'
    // },
    {
        key: 'Drop Off Task',
        value: 'drop_off_task'
    },
    {
        key: 'Break Task',
        value: 'break_task'
    },
    {
        key: 'Start Task',
        value: 'start_task'
    },
    {
        key: 'Return to HQ  Task',
        value: 'return_to_hq_task'
    },
]
