import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Typography, List, ListItem, TextField, IconButton, InputAdornment } from "@mui/material";
import driverPic from "../../assets/images/driver-pic.svg"
import DriverDetails from './DriverDetails';
import { useDispatch } from 'react-redux';
import { fetchDrivers } from '../../redux/slices/hyperdrive/driverAnalytics';
import SearchIcon from 'common/customIcons/search';
import { CloseRounded } from '@mui/icons-material';
import { useDebounce } from 'hooks/useDebounce';

const MapDriveList = () => {
    const dispatch = useDispatch();
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();
    const [detailsDriver, setDetailsDriver] = useState(null)
    const searchTermRef = useRef("")
    const [searchTerm, setSearchTerm] = useState("")

    const loadMorePosts = useCallback(async (data) => {
        setLoading(true);
        try {
            const limit = 20;
            const skip = data ? 0 : page * limit;
            const res = await dispatch(fetchDrivers({
                skip,
                limit,
                driverName: searchTermRef.current,
            })).unwrap();

            const newPosts = res?.data;

            if (newPosts?.length === 0) {
                setHasMore(false);
            } else {
                setPosts((prevPosts) => [...prevPosts, ...newPosts]);
            }
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [dispatch, page]);

    useEffect(() => {
        if (hasMore) {
            loadMorePosts();
        }
    }, [loadMorePosts, hasMore]);

    const lastPostElementRef = useCallback(
        (node) => {
            if (loading || !hasMore) return; 
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setPage((prevPage) => prevPage + 1);
                }
            });

            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const handleSearch = useDebounce(() => {
        setPage(0); 
        setPosts([]); 
        loadMorePosts("page");
    }, 500);

    const searchHandler = useCallback((searchValue) => {
        setSearchTerm(searchValue);
        searchTermRef.current = searchValue;
        handleSearch();
    }, [handleSearch]);

    function handleDataFromChild(data) {
        setDetailsDriver(null)
    }

    return (
        <>
            <TextField className="custom-search-field dark-field w-100 p-3" placeholder="Search" label=""
                value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        <IconButton size='small'>
                            <SearchIcon width='18px' height='18px' />
                        </IconButton>
                    </InputAdornment>,
                    endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                        <IconButton onClick={() => searchHandler('')} size='small'>
                            <CloseRounded />
                        </IconButton>
                    </InputAdornment> : null
                }}
            />
            <div className='hd-drivers-list-inner'>
                <List>
                    {posts?.map((data, idx) => (
                        <React.Fragment key={idx}>
                            <ListItem
                                ref={posts.length === idx + 1 ? lastPostElementRef : null}
                                className='d-flex align-item-top jc-s-btwn gap-2'
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setDetailsDriver(data?._id); 
                                }}
                            >
                                <div className='d-flex flex-row gap-2'>
                                    <div className='d-flex'>
                                        <img className='mt-1' src={driverPic} alt='' width={28} height={28} />
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <Typography variant='h6' className='text-14 text-white-700'>
                                            {data?.driverName}
                                        </Typography>
                                        <Typography className='text-12 text-black-200'>
                                            Eiusmod tempor
                                        </Typography>
                                    </div>
                                </div>
                                <Typography className='d-flex align-item-center gap-1 text-12'>
                                    <span className={data?.status ? 'dot-duty' : "dot-duty dot-off"}></span>
                                    <span className={data?.status ? 'driver-duty-txt' : 'driver-off-duty-txt'}>
                                        {data?.status ? "On duty" : "Off Duty"}
                                    </span>
                                </Typography>
                            </ListItem>
                            {loading && <p>Loading...</p>}
                        </React.Fragment>
                    ))}
                </List>
                {
                    detailsDriver &&
                    <DriverDetails sendDataToParent={handleDataFromChild} driverId={detailsDriver} />
                }
            </div>
        </>
    );
};

export default MapDriveList;
