import { CloseRounded, } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import SearchIcon from 'common/customIcons/search';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDebounce } from 'hooks/useDebounce';
import { AgGridTable } from 'components/tables/AgGridTable';
import { useDispatch } from 'react-redux';
import { getDriverAnalyticsInfo } from '../../../../redux/slices/hyperdrive/driverAnalytics';
import { useSelector } from 'react-redux';
import { formatTimestampToWithoutTime } from 'utilities/common';
import { openCustomModalDialog } from '../../../../redux/slices/modalSlice';
import StatusTask from 'common/component/StatusTask';
import moment from 'moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MDButton from "components/MDButton";
import VisibilityIcon from '@mui/icons-material/Visibility';


export default function DriverTask({ driverId }) {
  const dispatch = useDispatch()
  const pageRef = useRef(0)
  const searchTermRef = useRef("")
  const driverTermRef = useRef("")
  const mainGridApiRef = useRef('')
  const gridRefApi = useRef(null)
  const [searchTerm, setSearchTerm] = useState("")
  const [driverTerm, setDriverTerm] = useState("")
  const [formData, setFormData] = useState({ activeProducts: '', taskType: "", from: '', to: "" });
  const { driverDetails, loading: loading = false } = useSelector(state => state?.driverAnalyticsHyperDrive)
  const driverList = driverDetails?.data?.tasks
  const [filterShow, setFilterShow] = useState(true)
  const fromDateRef = useRef('');
  const toDateRef = useRef('');
  const [fromDate, setFromDate] = useState('');
  
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Order ID',
      field: 'taskDisplayId',
      suppressMenu: false,
      minWidth: 120,
      flex: 1,
      cellStyle: { whiteSpace: "normal", lineHeight: "1.5" }
    },
    {
      headerName: 'Task Type',
      field: 'taskType',
      suppressMenu: false,
      minWidth: 120,
      flex: 1.5,
      cellStyle: { whiteSpace: "normal", lineHeight: "1.5" },
      cellRenderer: useCallback((props) => (
        <StatusTask value={props?.data?.taskType} />
      ), []),
    },
    {
      headerName: 'Address',
      field: 'taskCount',
      suppressMenu: false,
      minWidth: 180,
      cellRenderer: useCallback((props) => (
        props?.data?.address ? `${props?.data?.address?.address1}, ${props?.data?.address?.city}, ${props?.data?.address?.state}, ${props?.data?.address?.country}, ${props?.data?.address?.zipcode || props?.data?.address?.zipCode}` : ""
      ), []),
      flex: 2.5,
    },
    {
      headerName: 'Recipient Name',
      field: 'recipientDetails',
      suppressMenu: false,
      minWidth: 120,
      flex: 1,
      cellRenderer: useCallback((props) => (
        props?.data?.recipientDetails?.name
      ), []),
      flex: 1,
    },
    {
      headerName: 'Status',
      field: 'taskStatus',
      suppressMenu: false,
      minWidth: 120,
      cellRenderer: useCallback((props) => (
        <StatusTask value={props?.data?.taskStatus} />
      ), []),
      flex: 1,
    },
    {
      headerName: 'Date',
      field: 'createdDate',
      suppressMenu: false,
      minWidth: 80,
      cellRenderer: useCallback((props) => (
        props?.value ? formatTimestampToWithoutTime(props?.value) : "-"
      ), []),
      flex: 1,
    },
    {
      headerName: 'Approved Miles',
      field: 'approvedMiles',
      suppressMenu: false,
      minWidth: 80,
      flex: 1,
    },
    {
      headerName: 'Total Miles',
      field: 'totalMiles',
      suppressMenu: false,
      minWidth: 80,
      flex: 0.8,
    },
    {
      headerName: 'Route',
      field: 'totalCash',
      suppressMenu: false,
      minWidth: 80,
      cellRenderer: useCallback((props) => (
        <MDButton
          variant="contained"
          color="light"
          size="small"
          onClick={() => handleCreateRoute(props?.data?._id)}
          className="d-flex align-item-center gap-2 px-3 text-black-400"
          style={{ fontWeight: "normal", textTransform: "capitalize" }}
        >
          <VisibilityIcon />
          <span>View Route</span>
        </MDButton>
      ), []),
      cellStyle: { textAlign: "right", justifyContent: "end" },
      headerClass: "right-aligned-cell-header",
      flex: 1,
    },
  ]);

  const handleCreateRoute = useCallback(async (data) => {
    try {
      await dispatch(openCustomModalDialog({
        modal: 'TASK_ROUTE',
        modalData: { data }
      }));
    } catch (error) {
      console.error('Error in approvalOverrideDialogHandler:', error);
    }
  }, [dispatch]);

  const getRows = useCallback(async (params, apply, taskStatus, taskType, dateCheck) => {
    console.log("dateCheck", dateCheck);

    gridRefApi.current = params;
    try {
      const res = await dispatch(getDriverAnalyticsInfo({
        driverId,
        data: {
          analyticType: "tasks",
          skip: pageRef.current,
          limit: 20,
          search: searchTermRef.current,
          taskType: apply && taskType ? taskType : "",
          taskStatus: apply && taskStatus ? taskStatus : "",
          from: dateCheck === "dateCheck" && fromDateRef.current ? fromDateRef.current : "",
          to: dateCheck === "dateCheck" && toDateRef.current ? toDateRef.current : "",
        }
      })).unwrap();

      const { tasks, totalTasks } = res?.data;
      const driverListArray = tasks?.map(item => ({ ...item }));
      params.successCallback([...driverListArray], totalTasks);
      pageRef.current += 20;
    } catch {
      params.successCallback([], 0);
    }
  }, [dispatch]);

  const searchFn = useCallback((apply, taskStatus, taskType, dateCheck) => {
    const dataSource = {
      getRows: (params) => getRows(params, apply, taskStatus, taskType, dateCheck)
    };
    mainGridApiRef.current.ensureIndexVisible(0, null);
    mainGridApiRef.current.setGridOption('datasource', dataSource);
  }, [formData?.taskType, getRows]);

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (driverDetails?.data?.totalTasks === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      } else {
        mainGridApiRef.current.hideOverlay();
      }
    }
  }, [driverList, loading]);

  const handleSearch = useDebounce(() => {
    pageRef.current = 0;
    searchFn("apply", formData?.activeProducts, formData?.taskType, "dateCheck");
  }, 500);

  const searchHandler = useCallback((searchValue) => {
    setSearchTerm(searchValue);
    searchTermRef.current = searchValue;
    handleSearch();
  }, [handleSearch]);

  const statusChangeHandler = (status) => {
    setFormData(prevState => ({
      ...prevState,
      activeProducts: status
    }));
  }

  const typeChangeHandler = (status) => {
    setFormData(prevState => ({
      ...prevState,
      taskType: status
    }));
  }
  // Date handlers
  const dateHandleFrom = (event) => {
    toDateRef.current = null;
    const selectedDate = event.target.value;
    const formattedDate = moment(selectedDate).toISOString();
    fromDateRef.current = formattedDate;
    setFromDate(formattedDate);
    if (toDateRef.current && moment(formattedDate).isAfter(moment(toDateRef.current))) {
      toDateRef.current = null;
    }
  }

  const dateHandleTo = (event) => {
    const selectedDate = event.target.value;
    const formattedDate = moment(selectedDate).toISOString();
    if (fromDateRef.current) {
      const fromDateMoment = moment(fromDateRef.current);
      const toDateMoment = moment(formattedDate);
      const diffDays = toDateMoment.diff(fromDateMoment, 'days');

      if (diffDays > 5) {
        alert('The "To" date must be within 5 days of the "From" date.');
        event.target.value = '';
        toDateRef.current = null;
        return;
      }
    }

    toDateRef.current = formattedDate;
    setFromDate(formattedDate);

    if (fromDateRef.current && moment(formattedDate).isBefore(moment(fromDateRef.current))) {
      event.target.value = '';
      toDateRef.current = null;
    } else {
      toDateRef.current = formattedDate;
    }
  }

  const clearAllHandler = useCallback(() => {
    pageRef.current = 0;
    driverTermRef.current = "";
    toDateRef.current = "";
    fromDateRef.current = "";
    setDriverTerm("");
    searchFn("clear");
    setFormData({ activeProducts: "", taskType: "" });
  }, [searchFn]);

  const applyHandler = () => {
    pageRef.current = 0;
    if (fromDateRef.current) {
      if (fromDateRef.current && toDateRef.current) {
        searchFn("apply", formData?.activeProducts, formData?.taskType, "dateCheck");
      }
      else {
        console.log("No Both Date Selected");        
        searchFn("apply", formData?.activeProducts, formData?.taskType);
      }
    }
    else {
      searchFn("apply", formData?.activeProducts, formData?.taskType);
    }
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" my={2}>
        <Typography variant='h4' className='text-white-700' fontSize="20px">Driver Task</Typography>
        <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="center" gap={1.5} className="hd-search-filter">
          <TextField className="custom-search-field dark-field" placeholder="Search" label=""
            value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">
                <IconButton size='small'>
                  <SearchIcon width='18px' height='18px' />
                </IconButton>
              </InputAdornment>,
              endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                <IconButton onClick={() => searchHandler('')} size='small'>
                  <CloseRounded />
                </IconButton>
              </InputAdornment> : null
            }}
          />
          <Button
            variant="outlined"
            color="primary"
            className="hd-filter-dropdown d-flex align-item-center gap-2"
            onClick={() => setFilterShow(!filterShow)}
          >
            <span>Filter</span>
            <ArrowDropDownIcon />
          </Button>
        </Stack>
      </Box>
      {
        filterShow &&
        <Box className="cus-filter-sec" mb={1}>
          <Grid className='filter-box' container alignItems="center" spacing={1.5}>
            <Grid item xs={12} sm={12} md={12} xxl={12}>
              <Box className="filter-price-unit">
                <Stack direction='row' justifyContent='flex-end' width='100%' gap={2} >
                  <Grid item xs={12} sm={12} md={12} xxl={12}>
                    <Box display="flex" alignItems="center" justifyContent='flex-end' gap={2} className="w-100">
                      <Box className="filter-price dark-field filter-min-w">
                        <Stack direction='row' alignItems="center" gap={1}>
                          <FormControl fullWidth sx={{ width: 300 }}>
                            <InputLabel>Task Type</InputLabel>
                            <Select size='small' value={formData?.taskType} onChange={(e) => { typeChangeHandler(e.target.value) }} fullWidth className='form-select' name='type' MenuProps={{ className: "cus-popover" }}>
                              <MenuItem value='startTask'>Start Task</MenuItem>
                              <MenuItem value='pickUpTask'>Pick Up Task</MenuItem>
                              <MenuItem value='deliveryTask'>Delivery Task</MenuItem>
                              <MenuItem value='breakTask'>Break Task</MenuItem>
                              <MenuItem value='returnToHeadquarterTask'>Return To Headquarter Task</MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>
                      </Box>
                      <Box className="filter-pro-status dark-field filter-min-w">
                        <Stack direction='row' alignItems="center" gap={1}>
                          <FormControl fullWidth sx={{ width: 300 }}>
                            <InputLabel>Status</InputLabel>
                            <Select size='small' value={formData?.activeProducts} onChange={(e) => { statusChangeHandler(e.target.value) }} fullWidth className='form-select' name='status' MenuProps={{ className: "cus-popover" }}>
                              <MenuItem value='completed'>Completed</MenuItem>
                              <MenuItem value='failed'>Failed</MenuItem>
                              <MenuItem value='in_progress'>Scheduled</MenuItem>
                              <MenuItem value='not_started'>Not Started</MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>
                      </Box>
                      <Typography variant='body2' fontSize="14px" className='text-black-200'>Date Range</Typography>
                      <Box className="filter-pro-status hd-date-field dark-field">
                        <Stack direction='row' alignItems="center" gap={1}>
                          <TextField
                            type='datetime-local'
                            name="tomorrow"
                            label="From"
                            InputLabelProps={{ shrink: true }}
                            onChange={dateHandleFrom}
                            value={fromDateRef.current ? moment(fromDateRef.current).format('YYYY-MM-DDTHH:mm') : ''}
                            sx={{ width: 220 }}
                          />
                          <TextField
                            type='datetime-local'
                            name="tomorrow"
                            label="To"
                            onChange={dateHandleTo}
                            InputLabelProps={{ shrink: true }}
                            value={toDateRef.current ? moment(toDateRef.current).format('YYYY-MM-DDTHH:mm') : ''}
                            sx={{ width: 220 }}
                            disabled={!fromDateRef.current}
                          />
                        </Stack>
                      </Box>
                      <Box className="filter-btns">
                        <Stack direction='row' alignItems="center" gap={1} className='w-100'>
                          <Button className="filter-clear-all" onClick={clearAllHandler} size="small" variant="text" color="secondary" style={{ width: "100px" }}>
                            Clear All
                          </Button>
                          <Button className="filter-apply" onClick={() => applyHandler()} size="small" variant="contained" color="primary" style={{ width: "120px" }}>
                            Apply
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      }
      <div className="ag-theme-quartz-dark">
        <AgGridTable
          col={columnDefs}
          mainGridApiRef={mainGridApiRef}
          getRows={getRows}
          pageRef={pageRef}
          isLoading={loading}
          style={{ height: "64vh" }}
          gridOptions={{ rowHeight: 64, }}
          maxLimit={20}
        />
      </div>
    </Box>
  )
}
