import axiosClientHyperDrive from "axiosClient/indexHyperdrive";
import { getQueryString } from "utilities/common";

// Main Setting API
export function fetchDriversApi(filters) {
    return axiosClientHyperDrive.get(`api/v1/admin/analytics/list?${getQueryString(filters)}`);
}
export function getDriverAnalyticsInfoApi(driverId, filters) {
    console.log("filters", filters);

    const url = (filters || filters?.analyticType != "") ? `api/v1/admin/analytics/details/${driverId}?${getQueryString(filters)}` : `api/v1/admin/analytics/details/${driverId}`
    return axiosClientHyperDrive.get(url);
}
export function getDriverAnalyticsExportApi(filters) {
    return axiosClientHyperDrive.get(`api/v1/admin/analytics/export?${getQueryString(filters)}`);
}

export function fetchRoutesDetailsApi(data) {
    return axiosClientHyperDrive.get(`api/v1/admin/analytics/view/route?taskId=${data}`);
}
