import { Box, Button, FormControl, FormControlLabel, Menu, RadioGroup, Radio, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { DateRange } from 'react-date-range';
import moment from 'moment';

const FiltersDriverTasks = ({ setAnchorEl, setAnchorTask, anchorEl, anchorTask, open, opentask, sendDataToChild }) => {
    const [calendarOpen, setCalendarOpen] = useState(false)
    const [formData, setFormData] = useState({
        taskStatus: '',
        driverStatus: ""
    });
    const today = new Date();

    const [dateRange, setDateRange] = useState([
        {
            startDate: today,
            endDate: today,
            key: 'selection'
        }
    ]);

    const allowedDays = [1, 2, 3, 4]; // Allowed range lengths

    const handleSelectRange = (item) => {
        const { startDate, endDate } = item.selection;
        const daysDifference = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
        if (allowedDays.includes(daysDifference)) {
            setDateRange([item.selection]);
        } else {
            const nearestAllowedDays = allowedDays.reduce((prev, curr) =>
                Math.abs(curr - daysDifference) < Math.abs(prev - daysDifference) ? curr : prev
            );
            const newEndDate = new Date(startDate);
            newEndDate.setDate(newEndDate.getDate() + nearestAllowedDays - 1);

            setDateRange([{ startDate, endDate: newEndDate, key: 'selection' }]);
        }
    };

    useEffect(() => {
        const formattedData = dateRange?.map(item => ({
            ...item,
            startDate: moment(item.startDate).format('YYYY-MM-DD'),
            endDate: moment(item.endDate).format('YYYY-MM-DD')
        }));
console.log("dataPassComponent formattedData, formData",formattedData, formData);

        sendDataToChild(formattedData, formData)
    }, [dateRange, formData])


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClickTask = (event) => {
        setAnchorTask(event.currentTarget);
    };

    const handleCloseTask = () => {
        setAnchorTask(null);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const submithandleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Box display="flex" alignItems="center" flexDirection="row" className="time-task-driver">
            <div className='d-flex align-item-center select-rep-sec'>
                <div className='d-flex align-item-center select-calendar'>
                    <span className='d-flex align-item-center cur-pointer' onClick={() => setCalendarOpen(!calendarOpen)}>
                        <Typography className='d-flex align-item-center'>
                            <AccessTimeFilledIcon />
                            <span>Today 12am-Tomorrow 12am</span>
                        </Typography>
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                            <g clipPath="url(#clip0_7876_6825)">
                                <path d="M5.5 8.5L1 4.5H10L5.5 8.5Z" fill="#5B5B65" />
                            </g>
                            <defs>
                                <clipPath id="clip0_7876_6825">
                                    <rect width="11" height="11" fill="white" transform="translate(0 0.5)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                    <div className='hd-calendar-main'>
                        {
                            calendarOpen &&
                            <DateRange
                                editableDateInputs={true}
                                onChange={handleSelectRange}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRange}
                                rangeColors={['#0163FF', '#0163FF', '#0163FF']}
                            />
                        }
                    </div>
                </div>
            </div>
            <div className='d-flex align-item-center select-rep-sec'>
                <div className='d-flex align-item-center select-with-radio'>
                    <Button id="all-drivers" aria-controls={open ? '' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                        <span className='d-flex align-item-center radio-colors gap-1'>
                            <span className='driver-off-duty'></span>
                            <span className='driver-idle'></span>
                            <span className='driver-in-transit'></span>
                        </span>
                        <Typography>All Drivers</Typography>
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                            <g clipPath="url(#clip0_7876_6825)">
                                <path d="M5.5 8.5L1 4.5H10L5.5 8.5Z" fill="#5B5B65" />
                            </g>
                            <defs>
                                <clipPath id="clip0_7876_6825">
                                    <rect width="11" height="11" fill="white" transform="translate(0 0.5)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </Button>
                    <Menu 
                        id="" 
                        anchorEl={anchorEl}
                        open={open} 
                        onClose={handleClose} 
                        MenuListProps={{ className: "hd-dropdown" }}
                    >
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="driver-status-group"
                                value={formData.driverStatus}
                                name="driverStatus"
                                className="radio-colors"
                                onChange={(e) => submithandleChange(e)}
                            >
                                <FormControlLabel className='field-off-duty' value="offDuty" control={<Radio />} label={<><span className='duty-color driver-off-duty'></span>Off-Duty</>} />
                                <FormControlLabel className='field-idle' value="idle" control={<Radio />} label={<><span className='duty-color driver-idle'></span>Idle</>} />
                                <FormControlLabel className='field-in-transit' value="inTransit" control={<Radio />} label={<><span className='duty-color driver-in-transit'></span>In Transit        </>} />
                            </RadioGroup>
                        </FormControl>
                    </Menu>
                </div>
            </div>
            <div className='d-flex align-item-center select-rep-sec'>
                <div className='d-flex align-item-center select-with-radio'>
                    <Button id="all-drivers" aria-controls={opentask ? '' : undefined} aria-haspopup="true" aria-expanded={opentask ? 'true' : undefined} onClick={handleClickTask}>
                        <span className='d-flex align-item-center radio-task-colors gap-1'>
                            <span className='task-unassigned'></span>
                            <span className='task-assigned'></span>
                            <span className='task-intransit'></span>
                            <span className='task-succeeded'></span>
                            <span className='task-failed'></span>
                        </span>
                        <Typography>All Tasks</Typography>
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                            <g clipPath="url(#clip0_7876_6825)">
                                <path d="M5.5 8.5L1 4.5H10L5.5 8.5Z" fill="#5B5B65" />
                            </g>
                            <defs>
                                <clipPath id="clip0_7876_6825">
                                    <rect width="11" height="11" fill="white" transform="translate(0 0.5)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </Button>
                    <Menu id="" anchorEl={anchorTask} open={opentask} onClose={handleCloseTask} MenuListProps={{ className: "hd-dropdown" }}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="radio-buttons-group"
                                value={formData?.taskStatus}
                                name="taskStatus"
                                className="radio-colors"
                                onChange={(e) => submithandleChange(e)}
                            >
                                <FormControlLabel className='field-unassigned' value="unAssigned" control={<Radio />} label={<><span className='task-color task-unassigned'></span>Unassigned</>} />
                                <FormControlLabel className='field-assigned' value="assigned" control={<Radio />} label={<><span className='task-color task-assigned'></span>Assigned</>} />
                                <FormControlLabel className='field-intransit' value="intransit" control={<Radio />} label={<><span className='task-color task-intransit'></span>In Transit</>} />
                                <FormControlLabel className='field-succeeded' value="succeeded" control={<Radio />} label={<><span className='task-color task-succeeded'></span>Succeeded</>} />
                                <FormControlLabel className='field-failed' value="failed" control={<Radio />} label={<><span className='task-color task-failed'></span>Failed</>} />
                            </RadioGroup>
                        </FormControl>
                    </Menu>
                </div>
            </div>
        </Box>
    )
}

export default FiltersDriverTasks
