import { useEffect, useState } from "react";
import { Box, Tab, Card, Paper, Grid, Typography, List, ListItem, Badge, Stack, Button } from "@mui/material";
import EastIcon from '@mui/icons-material/East';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import driverPic from "../../assets/images/driver-pic.svg"
import { getDriverAnalyticsInfo } from "../../redux/slices/hyperdrive/driverAnalytics";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { formatTimestampToDateView } from "utilities/common";
import CalculateMap from "./calculateMap";
import { formatTimestampToTime } from "utilities/common";
import { formatTimestampToWithoutTime } from "utilities/common";


const DriverDetails = ({ sendDataToParent = () => { }, driverId }) => {
    const [currentMainViewTab, setCurrentMainViewTab] = useState('driverInfo')
    const handleDriverDetails = () => {
        sendDataToParent(null);
    }
    const dispatch = useDispatch()
    const { driverDetails } = useSelector(state => state?.driverAnalyticsHyperDrive)
    const data = (currentMainViewTab == "tasks" ? "tasks" : currentMainViewTab == "checkInOut" ? "checklist" : "")

    useEffect(() => {
        dispatch(getDriverAnalyticsInfo({
            driverId, data: {
                ...(data !== "" && {
                    analyticType: data,
                    skip: 0,
                    limit: 20
                })
            }
        })).unwrap().then(res => { })
    }, [currentMainViewTab, driverId])

    return (
        <div className='driver-details-drawer'>
            <div className='d-flex align-item-center jc-s-btwn gap-2 driver-details-head'>
                <div className='d-flex flex-row gap-2'>
                    <div className='d-flex'>
                        <img className='mt-1' src={driverPic} alt='' width={28} height={28} />
                    </div>
                    <div className='d-flex flex-column'>
                        <Typography variant='h6' className='text-14 text-white-700'>{driverDetails?.data?.driverInfo?.fleetName}</Typography>
                        <Typography className='text-12 text-black-200'>Eiusmod tempor</Typography>
                    </div>
                </div>
                <Typography style={{ cursor: "pointer" }} onClick={() => handleDriverDetails("")} className='d-flex align-item-center gap-1 text-14 text-white-700'>
                    Close <EastIcon />
                </Typography>
            </div>
            <TabContext value={currentMainViewTab}>
                <Box className="hd-tabs hd-drawer-tabs">
                    <TabList
                        onChange={(e, value) => {
                            setCurrentMainViewTab(value)
                        }}
                        aria-label=""
                    >
                        <Tab label="Driver Info" value="driverInfo" />
                        <Tab label="Tasks" value="tasks" />
                        <Tab label="Check In / Check Out" value="checkInOut" />
                    </TabList>
                </Box>
                <TabPanel value="driverInfo" className='driver-info-tabs'>
                    <Card component={Paper} style={{ flexDirection: "row" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography className='text-black-200 text-14 mb-1'>Transportation</Typography>
                                <Typography variant='h5' className='text-white-700'>{driverDetails?.data?.driverInfo?.fleetVehicleDetails?.make}, {driverDetails?.data?.driverInfo?.fleetVehicleDetails?.model}, {driverDetails?.data?.driverInfo?.fleetVehicleDetails?.color}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className='text-black-200 text-14 mb-1'>Address</Typography>
                                <Typography variant='h5' className='text-white-700 d-flex align-item-top f-wrap gap-2'>
                                    <LocationOnIcon />
                                    <span>--</span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </TabPanel>
                <TabPanel value="tasks" className='driver-info-tabs'>
                    {/* <Stack direction='row' alignItems="center" justifyContent='end' className='w-100 mb-2'>
                    <Button style={{ width: "90px" }} variant="outlined" className="d-flex align-item-center jc-s-btwn cus-btn white-btn text-black-400 text-14 pl-3 pr-2 py-0 ">
                        Filters
                        <ArrowDropDownIcon className="m-0" />
                    </Button>
                </Stack> */}
                    <Card component={Paper} style={{ flexDirection: "row" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <List className="d-flex flex-column gap-3">
                                    {
                                        driverDetails?.data?.tasks?.map((data) => {
                                            return (
                                                <ListItem key={data?._id} className="d-flex flex-column p-3 bg-grey-900 border-radius-8 border-none gap-3">
                                                    <div className="w-100 d-flex align-item-center gap-2">
                                                        <div className="d-flex align-item-center">
                                                            <LocationOnIcon className="text-black-400" />
                                                        </div>
                                                        <div className="d-flex flex-column gap-2 driver-tabs-con">
                                                            <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                                <div className="d-flex align-item-center gap-2">
                                                                    <Badge className="text-black-400 bg-black-700 text-10 cus-badge">Pick Up</Badge>
                                                                </div>
                                                                <Typography className="text-black-400 text-12">{formatTimestampToDateView(data?.createdDate)}</Typography>
                                                            </div>
                                                            <Typography variant='h5' className='text-white-700 text-14 f-wrap line-height-normal'>
                                                                {data?.address ? `${data?.address?.address1}, ${data?.address?.city}, ${data?.address?.state}, ${data?.address?.country}, ${data?.address?.zipcode || data?.address?.zipCode}` : ""}                                                          </Typography>
                                                            <Typography className="text-white-700 text-12 line-height-normal">Stephanie Nicol</Typography>
                                                        </div>
                                                    </div>
                                                    <div className="w-100 d-flex">
                                                        <CalculateMap route={"detailRoute"} routeMap={data?.coordinates} />
                                                    </div>
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>
                            </Grid>
                        </Grid>
                    </Card>
                </TabPanel>
                <TabPanel value="checkInOut" className='driver-info-tabs'>
                    {/* <Stack direction='row' alignItems="center" justifyContent='end' className='w-100 mb-2'>
                    <Button style={{ width: "90px" }} variant="outlined" className="d-flex align-item-center jc-s-btwn cus-btn white-btn text-black-400 text-14 pl-3 pr-2 py-0 ">
                        Filters
                        <ArrowDropDownIcon className="m-0" />
                    </Button>
                </Stack> */}
                    <Card component={Paper} style={{ flexDirection: "row" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <List className="d-flex flex-column gap-3">
                                    {
                                        driverDetails?.data?.checkList?.map((data) => {
                                            return (
                                                <ListItem key={data?._id} className="d-flex flex-column p-3 bg-grey-900 border-radius-8 border-none gap-3">
                                                    <div className="w-100 d-flex flex-column">
                                                        <Typography variant='h4' className='text-white-700 text-16 f-wrap line-height-normal'>
                                                            {formatTimestampToWithoutTime(data?.checkIn)}
                                                        </Typography>

                                                        <div className="w-100 d-flex align-item-star my-2 gap-3">
                                                            <div className="w-100 d-flex align-item-star gap-2 border-radius-10 bg-black-800 pl-2 pr-3 py-2">
                                                                <PaidOutlinedIcon className="text-purple-100 text-24" />
                                                                <div className="w-100 d-flex flex-column align-item-center">
                                                                    <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                                        <Typography className="text-black-200 text-14">Check In</Typography>
                                                                        <Typography className="text-black-500 text-14">{formatTimestampToTime(data?.checkIn)}AM</Typography>
                                                                    </div>
                                                                    <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                                        <Typography className="text-black-200 text-14">Check Out</Typography>
                                                                        <Typography className="text-black-500 text-14">{formatTimestampToTime(data?.checkOut)}PM</Typography>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="w-100 d-flex align-item-star gap-2 border-radius-10 bg-black-800 pl-2 pr-3 py-2">
                                                                <PaidOutlinedIcon className="text-yellow-100 text-24" />
                                                                <div className="w-100 d-flex flex-column align-item-center">
                                                                    <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                                        <Typography className="text-black-200 text-14">Starting Cash</Typography>
                                                                        <Typography className="text-black-500 text-14">{data?.drawerStartingCash == "" ? `$${data?.drawerStartingCash}` : "0"}</Typography>
                                                                    </div>
                                                                    <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                                        <Typography className="text-black-200 text-14">Total Cash</Typography>
                                                                        <Typography className="text-black-500 text-14">{data?.drawerTotalCash == "" ? `$${data?.drawerTotalCash}` : "0"}</Typography>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <Typography className="text-black-400 font-w-600 text-14">Total Mileage</Typography>

                                                        <div className="w-100 d-flex gap-3">
                                                            <Typography className="text-black-400 text-14">
                                                                Approved Miles
                                                                <span className="text-white-900 font-w-600 text-16 ml-2">--</span>
                                                            </Typography>
                                                            <Typography className="text-black-400 text-14">
                                                                Total Hours
                                                                <span className="text-white-900 font-w-600 text-16 ml-2">{data?.totalHours}</span>
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </ListItem>

                                            )
                                        })
                                    }


                                    <ListItem className="d-flex flex-column p-3 bg-grey-900 border-radius-8 border-none gap-3">
                                        <div className="w-100 d-flex flex-column">
                                            <Typography variant='h4' className='text-white-700 text-16 f-wrap line-height-normal'>
                                                Yesterday
                                            </Typography>

                                            <div className="w-100 d-flex align-item-star my-2 gap-3">
                                                <div className="w-100 d-flex align-item-star gap-2 border-radius-10 bg-black-800 pl-2 pr-3 py-2">
                                                    <PaidOutlinedIcon className="text-purple-100 text-24" />
                                                    <div className="w-100 d-flex flex-column align-item-center">
                                                        <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                            <Typography className="text-black-200 text-14">Check In</Typography>
                                                            <Typography className="text-black-500 text-14">9:30 AM</Typography>
                                                        </div>
                                                        <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                            <Typography className="text-black-200 text-14">Check Out</Typography>
                                                            <Typography className="text-black-500 text-14">6:30 PM</Typography>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="w-100 d-flex align-item-star gap-2 border-radius-10 bg-black-800 pl-2 pr-3 py-2">
                                                    <PaidOutlinedIcon className="text-yellow-100 text-24" />
                                                    <div className="w-100 d-flex flex-column align-item-center">
                                                        <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                            <Typography className="text-black-200 text-14">Starting Cash</Typography>
                                                            <Typography className="text-black-500 text-14">$10</Typography>
                                                        </div>
                                                        <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                            <Typography className="text-black-200 text-14">Total Cash</Typography>
                                                            <Typography className="text-black-500 text-14">$30</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <Typography className="text-black-400 font-w-600 text-14">Total Mileage</Typography>

                                            <div className="w-100 d-flex gap-3">
                                                <Typography className="text-black-400 text-14">
                                                    Approved Miles
                                                    <span className="text-white-900 font-w-600 text-16 ml-2">170</span>
                                                </Typography>
                                                <Typography className="text-black-400 text-14">
                                                    Total Hours
                                                    <span className="text-white-900 font-w-600 text-16 ml-2">30</span>
                                                </Typography>
                                            </div>
                                        </div>
                                    </ListItem>

                                    {/* <ListItem className="d-flex flex-column p-3 bg-grey-900 border-radius-8 border-none gap-3">
                                        <div className="w-100 d-flex flex-column">
                                            <Typography variant='h4' className='text-white-700 text-16 f-wrap line-height-normal'>
                                                30 July,2024
                                            </Typography>

                                            <div className="w-100 d-flex align-item-star my-2 gap-3">
                                                <div className="w-100 d-flex align-item-star gap-2 border-radius-10 bg-black-800 pl-2 pr-3 py-2">
                                                    <PaidOutlinedIcon className="text-purple-100 text-24" />
                                                    <div className="w-100 d-flex flex-column align-item-center">
                                                        <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                            <Typography className="text-black-200 text-14">Check In</Typography>
                                                            <Typography className="text-black-500 text-14">9:30 AM</Typography>
                                                        </div>
                                                        <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                            <Typography className="text-black-200 text-14">Check Out</Typography>
                                                            <Typography className="text-black-500 text-14">6:30 PM</Typography>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="w-100 d-flex align-item-star gap-2 border-radius-10 bg-black-800 pl-2 pr-3 py-2">
                                                    <PaidOutlinedIcon className="text-yellow-100 text-24" />
                                                    <div className="w-100 d-flex flex-column align-item-center">
                                                        <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                            <Typography className="text-black-200 text-14">Starting Cash</Typography>
                                                            <Typography className="text-black-500 text-14">$10</Typography>
                                                        </div>
                                                        <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                            <Typography className="text-black-200 text-14">Total Cash</Typography>
                                                            <Typography className="text-black-500 text-14">$30</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <Typography className="text-black-400 font-w-600 text-14">Total Mileage</Typography>

                                            <div className="w-100 d-flex gap-3">
                                                <Typography className="text-black-400 text-14">
                                                    Approved Miles
                                                    <span className="text-white-900 font-w-600 text-16 ml-2">170</span>
                                                </Typography>
                                                <Typography className="text-black-400 text-14">
                                                    Total Hours
                                                    <span className="text-white-900 font-w-600 text-16 ml-2">30</span>
                                                </Typography>
                                            </div>
                                        </div>
                                    </ListItem>

                                    <ListItem className="d-flex flex-column p-3 bg-grey-900 border-radius-8 border-none gap-3">
                                        <div className="w-100 d-flex flex-column">
                                            <Typography variant='h4' className='text-white-700 text-16 f-wrap line-height-normal'>
                                                29 July,2024
                                            </Typography>

                                            <div className="w-100 d-flex align-item-star my-2 gap-3">
                                                <div className="w-100 d-flex align-item-star gap-2 border-radius-10 bg-black-800 pl-2 pr-3 py-2">
                                                    <PaidOutlinedIcon className="text-purple-100 text-24" />
                                                    <div className="w-100 d-flex flex-column align-item-center">
                                                        <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                            <Typography className="text-black-200 text-14">Check In</Typography>
                                                            <Typography className="text-black-500 text-14">9:30 AM</Typography>
                                                        </div>
                                                        <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                            <Typography className="text-black-200 text-14">Check Out</Typography>
                                                            <Typography className="text-black-500 text-14">6:30 PM</Typography>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="w-100 d-flex align-item-star gap-2 border-radius-10 bg-black-800 pl-2 pr-3 py-2">
                                                    <PaidOutlinedIcon className="text-yellow-100 text-24" />
                                                    <div className="w-100 d-flex flex-column align-item-center">
                                                        <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                            <Typography className="text-black-200 text-14">Starting Cash</Typography>
                                                            <Typography className="text-black-500 text-14">$10</Typography>
                                                        </div>
                                                        <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                            <Typography className="text-black-200 text-14">Total Cash</Typography>
                                                            <Typography className="text-black-500 text-14">$30</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <Typography className="text-black-400 font-w-600 text-14">Total Mileage</Typography>

                                            <div className="w-100 d-flex gap-3">
                                                <Typography className="text-black-400 text-14">
                                                    Approved Miles
                                                    <span className="text-white-900 font-w-600 text-16 ml-2">170</span>
                                                </Typography>
                                                <Typography className="text-black-400 text-14">
                                                    Total Hours
                                                    <span className="text-white-900 font-w-600 text-16 ml-2">30</span>
                                                </Typography>
                                            </div>
                                        </div>
                                    </ListItem> */}

                                </List>
                            </Grid>
                        </Grid>
                    </Card>
                </TabPanel>
            </TabContext>
        </div>
    )
}

export default DriverDetails