import { Typography, Box } from '@mui/material';
import React from 'react'
import EastIcon from '@mui/icons-material/East';
import NearMeIcon from '@mui/icons-material/NearMe';
import streetMap from './../../../assets/images/Street-Map.png'


const TaskRoute = ({ sendDataToParent = () => { }}) => {
    const handleDriverDetails = () => {
        sendDataToParent(null);
    }
    return (
        <div className="next-drawer">
            <Box p={2} className='d-flex align-item-center jc-s-btwn gap-2 drawer-head'>
                <div className='d-flex flex-row gap-3'>
                    <Typography variant='h6' className='text-14 text-white-700'>Route</Typography>
                    <Typography className='text-14 text-black-400'>a3281f64</Typography>
                </div>
                <Typography style={{ cursor: "pointer" }} onClick={() => handleDriverDetails()} className='d-flex align-item-center gap-1 text-14 text-white-700'>
                    Close <EastIcon />
                </Typography>
            </Box>

            <Box className='drawer-scroll-sec'>
                <Box p={2}>
                    <div className='d-flex align-item-top gap-3'>
                        <NearMeIcon style={{ color:'#0163FF', marginTop: "5px" }}/>
                        <div className='d-flex align-item-top flex-column'>
                            <Typography className='text-white-700' fontSize={14} fontWeight="semibold">Driver Location at</Typography>
                            <Typography className='text-black-400' fontSize={20} fontWeight="semibold" mb={1}>1:23 AM</Typography>
                            <Typography className='text-white-700' fontSize={14} fontWeight="semibold">22.872312.-117.5893 at 0.14 miles elevation</Typography>
                            
                        </div>
                    </div>

                    <div className='street-map-view mt-4'>
                        <img src={streetMap} alt="" />
                    </div>
                </Box>
            </Box>
        </div>
    )
}

export default TaskRoute
