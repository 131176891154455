
// @mui material components
import Grid from "@mui/material/Grid";
import CommonLayout from "layouts/DashboardLayout";
import { Box } from "@mui/material";
import StatsAnalyzeChart from "components/hyperdrive/analyze/StatsAnalyzeChart";
import CompletedTaskChart from "components/hyperdrive/analyze/CompletedTaskChart";


function Analyze() {

  return (
    <Box p={{ xs: 2, sm: 3, lg: 4}} className="pb-0 h-100">
      <Grid container spacing={3}>
        <Grid item lg={4}>
          <StatsAnalyzeChart />
        </Grid>
        <Grid item lg={8}>
           <CompletedTaskChart/>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Analyze;
