import { DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import CalculateMap from 'components/hyperdrive/calculateMap';
import NearMeIcon from '@mui/icons-material/NearMe';
import { useEffect } from 'react';
import { fetchRoutesDetails } from '../../redux/slices/hyperdrive/driverAnalytics';


export default function DriverTaskModal({ closeModal }) {
    const dispatch = useDispatch()
    const { modalData, modal, isLoading = false } = useSelector(state => state.modal)
    const { driverRoutesDetails = {} } = useSelector(state => state.driverAnalyticsHyperDrive)


    const fetch = async () => {
        try {
            const res = await dispatch(fetchRoutesDetails(modalData?.data)).unwrap();

        } catch (err) {
            console.error("Response Error:", err);
            // Handle the error here
        }
    }
    useEffect(() => {
        fetch()
    }, [])
    return (
        <>
            <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between"  >
                <div className='d-flex align-item-top gap-3'>
                    <NearMeIcon style={{ color: '#0163FF', marginTop: "5px" }} />
                    <div className='d-flex align-item-top flex-column'>
                        <Typography className='text-white-700' fontSize={14} fontWeight="semibold">Driver Location at 1:23 AM</Typography>
                        <Typography className='text-white-700' fontSize={14} fontWeight="semibold">22.872312.-117.5893 at 0.14 miles elevation</Typography>
                    </div>
                </div>
                <IconButton onClick={closeModal} edge='end' size='small' className='modal-close'>
                    <CloseIcon fontSize='medium' />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <CalculateMap route={""} routeMap={driverRoutesDetails?.data} />
            </DialogContent>
        </>
    )
}