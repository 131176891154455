import { Box, Typography } from '@mui/material';
import ActiveIcon from 'common/customIcons/active';
import InActiveIcon from 'common/customIcons/inactive';
import InProgressIcon from 'common/customIcons/inprogress';
import React from 'react';

const statusComponents = {
    deliveryTask: <p >Delivery Task</p>,
    returnToHeadquarterTask: <p >Return to Headquarter Task</p>,
    startTask: <p >Start Task</p>,
    breakTask: <p >Break Task</p>,
    pickUpTask: <p >Pickup Task</p>,

    not_started: <Box color="#8E0CF5" display="flex" alignItems="center">
        {/* <InActiveIcon width="18px" height="18px" flex="none" /> */}
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Not Started</Typography>
    </Box>,
    in_progress: <Box color="#8E0CF5" display="flex" alignItems="center">
        <InProgressIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Scheduled</Typography>
    </Box>,
    completed: <Box color="#24CA49" display="flex" alignItems="center">
        <ActiveIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Completed</Typography>
    </Box>,
    failed: <Box color="#FD4438" display="flex" alignItems="center">
        <InActiveIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Failed</Typography>
    </Box>,
    onduty: <Box color="#24CA49" display="flex" alignItems="center">
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">On Duty</Typography>
    </Box>,
    offduty: <Box color="#FD4438" display="flex" alignItems="center">
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Off Duty</Typography>
    </Box>
};

const StatusTask = ({ value }) => {

    const content = statusComponents[value] || <p style={{ color: "gray" }}>--</p>;
    return (
        <div style={{ fontWeight: "500" }}>
            {content}
        </div>
    );
};

export default StatusTask;