import { Close } from '@mui/icons-material'
import { Box, Button, FormHelperText,  Grid,  Stack, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import GoogleSuggestion from 'components/googleMaps/GoogleSuggestion'
import moment from 'moment'
import MapDriveListDrawer from './MapDriveListDrawer';
import { addStartTask } from '../../../redux/slices/hyperdrive/createTask';
import { openSnackbar } from '../../../redux/slices/common';

export default function ReturnToHQ({ closeModal }) {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({})
    const addressError = useRef(false)
    const [formData, setFormData] = useState({
        // task_id: "",
        address: "",
        taskStartTime: "",
        taskEndTime: "",
        taskAssignmentMode: "automatic",
        taskType: "returnToHeadquarterTask",
        fleetId: ""
    })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showManualList, setShowManualList] = useState(false);
    const open = Boolean(anchorEl);

      const startTaskHandler = (event) => {
        const { name, value } = event.target;
        const formatDateString = (dateString) => {
            const date = moment(dateString);
            if (!date.isValid()) {
                return '';
            }
            return date.format('YYYY-MM-DD HH:mm:ss');
        };
        const formattedValue = (name === 'taskAssignmentMode') ? value : (name === 'taskStartTime' || name === 'taskEndTime') ? formatDateString(value) : value; setFormData(prevData => {
            const newData = {
                ...prevData,
                [name]: formattedValue
            };

            if (name === 'taskAssignmentMode') {
                if (value === "manual") {
                    setShowManualList(true)
                }
                else {
                    setErrors({ fleetId: "" })
                    setShowManualList(false)
                }
            }
            const startDate = moment(newData.taskStartTime);
            const endDate = moment(newData.taskEndTime);
            if (name === 'taskStartTime' && newData.taskEndTime) {
                if (endDate.isBefore(startDate)) {
                    setErrors(prevData => ({ ...prevData, taskStartTime: "Start date must be before the end date." }))
                    // alert('Start date must be before the end date.');
                    return prevData;
                }
            }
            if (name === 'taskEndTime' && newData.taskStartTime) {
                if (endDate.isBefore(startDate)) {
                    setErrors(prevData => ({ ...prevData, taskEndTime: "End date must be after the start date." }))
                    // alert('End date must be after the start date.');
                    return prevData;
                }
            }
            setErrors({ taskStartTime: "", taskEndTime: "" })
            return newData;
        });
    };

    const validateTask = () => {
        let isError = false
        let errors = {}
        if (!formData?.taskDescription?.trim()) {
            isError = true
            errors = { ...errors, taskDescription: 'Task Description is required' }
        }
        if (!formData?.taskStartTime?.trim()) {
            isError = true
            errors = { ...errors, taskStartTime: 'Start Date is required' }
        }
        if (!formData?.taskEndTime?.trim()) {
            isError = true
            errors = { ...errors, taskEndTime: 'End Date is required' }
        }
        return { errors, isError }
    }

    const submitHandler = () => {
        const validationResult = validateTask();
        let isError = validationResult.isError;
        let errors = { ...validationResult.errors };
        setErrors(errors);
        if (isError) return;
        if (formData?.taskAssignmentMode === "manual") {
            if (!formData?.fleetId?.trim()) {
                isError = true;
                errors = { ...errors, fleetId: 'Fleet ID is required' };
                setErrors(errors);
                return false
            }
        }
        dispatch(addStartTask({ data: formData }))
            .unwrap()
            .then(res => {
                showResponseMessage(res?.message, 'success');
                closeModal()
            })
            .catch(err => {
                showResponseMessage(err?.message, 'error');
            });

    }
    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const googleAddressErrorHandler = (status = false) => {
        addressError.current = status
        if (status) {
            setErrors({ ...errors, address: 'Address is required' })
        }
        else {
            setErrors(prevErrors => ({ ...prevErrors, address: '' }));
        }
    }

    function handleDataFromChild() {
        setShowManualList(false)
    }

    const handleFleetValueChange = (value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            fleetId: value
        }));
    };

    const handleAddressChange = (addressed) => {
        const address = {
            address1: addressed?.address || "",
            city: addressed?.city || "",
            state: addressed?.state || "",
            zipcode: addressed?.zipcode || "",
            country: addressed?.country || "USA",
            latitude: addressed?.lat ? addressed.lat.toFixed(8) : "",
            longitude: addressed?.long ? addressed.long.toFixed(8) : "",

        };
        setFormData({ ...formData, address });
    }
    return (
        <Box className="cus-wrapper-drawer">
            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} style={{ borderBottom: "1px solid  #1E1E24" }} className="drawer-head-sec">
                <Typography className="text-14 text-white-700">Create Return to HQ Task</Typography>
                <Close onClick={closeModal} style={{ cursor: "pointer", color: "#ffffff" }} />
            </Box>

            <Box className="drawer-scroll-sec">
                <Box px={2} py={4}>
                    
                    <Stack direction='column' mb={4}>
                        <Typography mb={1} fontSize={22} fontWeight={600} className='text-white-700'>Task Info</Typography>
                        <Stack direction='column' gap={2} mb={1} className='dark-field'>
                            <TextField
                                fullWidth
                                helperText={errors.task_id}
                                error={errors?.task_id}
                                label={'Task Id'}
                                name="task_id"
                                onChange={(e) => startTaskHandler(e)}
                                className="form-input"
                                value={formData?.task_id || ''}
                            />
                        </Stack>
                    </Stack>

                    <Stack direction='column' mb={4}>
                        <Typography mb={1} fontSize={22} fontWeight={600} className='text-white-700'>Headquarters Address</Typography>
                        <Stack direction='column' gap={2} mb={1} className='dark-field hd-google-suggestions'>
                            <GoogleSuggestion addressValue={formData?.address?.address} googleAddressErrorHandler={googleAddressErrorHandler}
                                onClearAddress={(address) => setFormData({ ...formData, address })}
                                addressChangeHandler={(address) => handleAddressChange(address)} />
                            {errors?.address && <FormHelperText error>{errors?.address}</FormHelperText>}
                        </Stack>
                    </Stack>

                    <Stack direction='column' mb={4}>
                        <Typography fontSize={22} fontWeight={600} className='text-white-700'>Time Window</Typography>
                        <Typography mb={2} fontSize={16} fontWeight={400} className='text-black-600'>For tasks with a schedule or estimated fullfillment time.</Typography>
                  
                        <Grid container spacing={2} className='drawer-date-field dark-field'>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField
                                    helperText={errors.taskStartTime}
                                    error={errors?.taskStartTime}
                                    type='datetime-local'
                                    name="taskStartTime"
                                    label="Start"
                                    onChange={(e) => startTaskHandler(e)}
                                    value={formData?.taskStartTime ? moment(formData?.taskStartTime).format('YYYY-MM-DDTHH:mm') : ''}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: 220 }}
                                    inputProps={{
                                        min: moment().format('YYYY-MM-DDTHH:mm') // Disable past dates
                                      }}
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField
                                    helperText={errors?.taskEndTime}
                                    error={errors?.taskEndTime}
                                    type='datetime-local'
                                    name="taskEndTime"
                                    label="End"
                                    onChange={(e) => startTaskHandler(e)}
                                    value={formData?.taskEndTime ? moment(formData?.taskEndTime).format('YYYY-MM-DDTHH:mm') : ''}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: 220 }}
                                    inputProps={{
                                        min: moment().format('YYYY-MM-DDTHH:mm') // Disable past dates
                                      }}
                                />
                            </Grid>
                        </Grid>
                    </Stack>
                
                </Box>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} gap={2} style={{ borderTop: "1px solid  #1E1E24" }} className="drawer-bottom-btns">
                <Button 
                    variant="outlined" 
                    color="secondary" 
                    className='d-flex align-item-center gap-2'
                    onClick={closeModal}
                >
                    Cancel
                </Button>
                <Button 
                    variant="contained" 
                    color="primary" 
                    className='d-flex align-item-center gap-2 green-btn'
                    onClick={() => submitHandler()}
                >
                    Create Task
                </Button>
            </Box>

            {showManualList &&
                <MapDriveListDrawer sendDataToParent={handleDataFromChild} onValueChange={handleFleetValueChange} />
            }
        </Box>
    )
}
