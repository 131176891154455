import { Autocomplete, Box, Card, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import AngleRightIcon from 'common/customIcons/angle_right'
import EditIcon from 'common/customIcons/edit'
import MDButton from 'components/MDButton'
import React, { useEffect, useMemo, useState } from 'react'
import colors from "assets/theme/base/colors";
import { Link, useFetcher, useNavigate, useParams } from 'react-router-dom'
import DeleteIcon from 'common/customIcons/delete'
import DateRange from 'components/dateRangePicker/DateRange'
import { useSelector } from 'react-redux'
import { generatePromoCodes } from 'utilities/common'
import { useDispatch } from 'react-redux'
import { createPromotion, getPromotionCartRulesByType, getPromotionProductRulesByType, updatePromotion } from '../../redux/slices/promotions'
import { fetchBrands } from '../../redux/slices/brand'
import { fetchCategories, openSnackbar } from '../../redux/slices/common'
import { fetchProducts } from '../../redux/slices/products'
import { isNumber } from 'utilities/common'
import { MobileTimePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import dayjs from 'dayjs'
import { isEmptyObject } from 'utilities/common'
import { isValidNumber } from 'utilities/common'
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { parseDate } from 'utilities/common'




dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
export default function AddPromotionForm() {
  const { grey } = colors;

  const { promotionRules = [], promotionDetail = {}, isLoading = false } = useSelector(state => state.promotion)
  const { brandsData } = useSelector(state => state.brands)
  const { categoriesData } = useSelector(state => state.common)
  const { products } = useSelector(state => state.products)
  const [dateRange, setDateRange] = useState([null, null]);
  const [errors, setErrors] = useState({})
  const { promotionId } = useParams()
  const navigate = useNavigate()

  const [startTime, setStarTime] = useState(new Date());

  const dispatch = useDispatch()
  const [promotionData, setPromotionData] = useState({
    promotionInfo: {
      promoName: '',
      promoCodes: []
    },
    discount: {
      discountType: '',
      discountAmount: ''
    },
    promotionProperty: {
      promotionType: '',
      autoApply: "No",
      stackable: 'No'
    },
    promotionDate: {
      date: '',
      week: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
      startTime: null,
      endTime: null,
      startDate: '',
      endDate: ''
    },
    promotionAvailability: {
      maxAvailablity: '',
      maxAvailableCustomer: '',
      availableUnits: '',
    },
    promoDescription: '',
    criteriaGroups: [],
    target: [],
    status: false
  })
  const [codeGenerationCount, setCodeGenerationCount] = useState('')

  const productRules = useMemo(() => {
    if (promotionData?.promotionProperty?.promotionType === 'Cart') {
      return promotionRules?.cartRules?.map((rule => rule?.ruleType)) || []
    }
    if (promotionData?.promotionProperty?.promotionType === 'Product') {
      return promotionRules?.productRules?.map((rule => rule?.ruleType)) || []
    }
    return []
  }, [promotionRules, promotionData?.promotionProperty?.promotionType])


  const promotionType = useMemo(() => {
    return promotionData?.promotionProperty?.promotionType
  }, [promotionData?.promotionProperty?.promotionType])

  const criteriaGroups = useMemo(() => {
    return promotionData?.criteriaGroups
  }, [promotionData?.criteriaGroups])


  const target = useMemo(() => {
    return [...promotionData?.target]
  }, [promotionData])

  const promotionChangeHandler = (event, parentKey = '', childKey = '') => {
    if (((childKey === 'discountAmount' && promotionData?.discount?.discountType === 'Percentage') || childKey === 'maxAvailableCustomer') && event.target.value && !isNumber(event.target.value)) return;
    if (childKey === 'discountAmount' && promotionData?.discount?.discountType === 'Cash' && event.target.value && !isValidNumber(event.target.value)) return;

    if (childKey === 'discountAmount' && promotionData?.discount?.discountType === 'Percentage' && event?.target.value > 100) return;
    if (childKey === 'promotionType') {
      setPromotionData({
        ...promotionData, promotionProperty: { ...promotionData?.promotionProperty, promotionType: event.target.value }, target: [], criteriaGroups: []
      })
      if (event?.target.value === 'Cart') {
        dispatch(getPromotionCartRulesByType())
      }
      if (event?.target.value === 'Product') {
        dispatch(getPromotionProductRulesByType())
      }
      return;
    }

    if (parentKey === 'discount' && childKey === 'discountType') {
      setPromotionData({ ...promotionData, discount: { ...promotionData.discount, discountAmount: '', discountType: event.target.value, cashUpto: "" } })
      return;
    }



    if (childKey === 'maxAvailablity') {
      setPromotionData({ ...promotionData, promotionAvailability: { ...promotionData.promotionAvailability, maxAvailableCustomer: '', maxAvailablity: event.target.value } })
      return;
    }

    if (!childKey) {
      setPromotionData({ ...promotionData, [parentKey]: event.target.value })
      return;
    }

    if (childKey) {
      setPromotionData({ ...promotionData, [parentKey]: { ...promotionData[parentKey], [childKey]: event.target.value } })
    }
  }


  const productRuleChangeHandler = (event, idx) => {
    const currentTargetGroup = [...promotionData?.target]
    let selectedTarget = currentTargetGroup?.find((item, index) => index === idx);
    if (selectedTarget) {
      let selectedTargetIndex = currentTargetGroup?.indexOf(selectedTarget)
      selectedTarget = {
        ...selectedTarget,
        "categoryIds": [],
        "productIds": [],
        "brandIds": [],
        "productTags": [],
        "minAmt": '',
        "maxAmt": '',
        "discountType": "",
        "discountAmt": '',
        [event.target.name]: event.target.value
      }
      currentTargetGroup.splice(selectedTargetIndex, 1, selectedTarget)
      setPromotionData({ ...promotionData, target: currentTargetGroup })
    }
  }

  const addProductRule = () => {
    let newProductRule = promotionData?.target
    newProductRule = [...newProductRule, {
      "categoryIds": [],
      "productIds": [],
      "brandIds": [],
      "productTags": [],
      "minAmt": '',
      "maxAmt": '',
      "discountType": "",
      "discountAmt": '',
      ruleType: ''
    }]
    setPromotionData({ ...promotionData, target: newProductRule })
  }
  const addNewCartRule = () => {
    let newCartRule = promotionData?.criteriaGroups
    newCartRule = [...newCartRule, {
      "minAmt": '',
      "maxAmt": '',
      ruleType: ''
    }]
    setPromotionData({ ...promotionData, criteriaGroups: newCartRule })
  }

  const deleteProductRuleHandler = (ruleIdx) => {
    let newProductRule = [...promotionData?.target]
    newProductRule = newProductRule?.filter(((rule, idx) => ruleIdx !== idx))
    setPromotionData({ ...promotionData, target: newProductRule })
  }


  const deleteCartRuleHandler = (ruleIdx) => {
    let newProductRule = [...promotionData?.criteriaGroups]
    newProductRule = newProductRule?.filter(((rule, idx) => ruleIdx !== idx))
    setPromotionData({ ...promotionData, criteriaGroups: newProductRule })
  }

  const cartRuleChangeHandler = (event, idx) => {
    if (event?.target.name === 'minAmt' && event.target.value && !isNumber(event.target.value)) return;
    let currentCriterialGroup = [...promotionData?.criteriaGroups]
    let selectedTarget = currentCriterialGroup?.find((item, index) => index === idx);
    if (selectedTarget) {

      let selectedTargetIndex = currentCriterialGroup?.indexOf(selectedTarget)
      selectedTarget = {
        ...selectedTarget,
        minAmt: '',
        maxAmt: '',
        [event.target.name]: event?.target.name === 'minAmt' ? Number(event.target.value) : event.target.value
      }
      currentCriterialGroup.splice(selectedTargetIndex, 1, selectedTarget)
      setPromotionData({ ...promotionData, criteriaGroups: currentCriterialGroup })
    }

  }
  const productAutoCompletChangeHandler = (options, valueKey, idx) => {
    let currentTargetGroup = [...promotionData?.target]
    let selectedTarget = currentTargetGroup?.find((item, index) => index === idx);
    if (selectedTarget) {
      let selectedTargetIndex = currentTargetGroup?.indexOf(selectedTarget)
      selectedTarget = {
        ...selectedTarget,
        [valueKey]: options
      }
      currentTargetGroup.splice(selectedTargetIndex, 1, selectedTarget)
      setPromotionData({ ...promotionData, target: currentTargetGroup })
    }
  }

  const autoGenerateCode = () => {
    setPromotionData({ ...promotionData, promotionInfo: { ...promotionData?.promotionInfo, promoCodes: [...promotionData?.promotionInfo?.promoCodes, ...generatePromoCodes(codeGenerationCount)] } })
  }

  const validatePromotion = () => {
    let isError = false
    let errors = {}
    if (!promotionData?.discount?.discountType?.trim()) {
      isError = true
      errors = { ...errors, discountType: 'Discount Type is required' }
    }
    if (!promotionData?.promotionInfo?.promoName?.trim()) {
      isError = true
      errors = { ...errors, promotionName: 'Promo Name is required' }
    }
    if (promotionData?.promotionInfo?.promoCodes?.length === 0) {
      isError = true
      errors = { ...errors, promoCodes: 'Atleast one promo code is required' }
    }
    if (!promotionData?.promotionProperty?.promotionType) {
      isError = true
      errors = { ...errors, promotionType: 'Promotion type is required' }
    }
    if (promotionData?.discount?.discountType && !promotionData?.discount?.discountAmount) {
      isError = true
      errors = { ...errors, discountAmount: promotionData?.discount?.discountType === 'Cash' ? 'discount amount is required' : 'discount percentage is required' }
    }
    return { errors, isError }
  }

  const submitHandler = () => {
    const { isError, errors } = validatePromotion()
    // if (promotionData?.discount?.discountType && !promotionData?.discount?.cashUpto && promotionData?.discount?.discountType === 'Percentage') {
    //   isError = true
    //   errors = { ...errors, cashUpto: 'Discount upto is required' }
    // }
    setErrors(errors)
    // if (promotionData?.promotionInfo?.promoCodes?.length === 0) {
    //   isError = true
    //   setErrors({ promoCodes: 'Atleast one promo code is required' })
    // }
    if (isError) return;
    setErrors({})
    let data = {
      ...promotionData.promotionInfo,
      ...promotionData.discount,
      ...promotionData.promotionProperty,
      ...promotionData.promotionDate,
      ...promotionData.promotionAvailability,
      promoDescription: promotionData?.promoDescription,
      criteriaGroups: promotionData.criteriaGroups,
      target: promotionData.target,
      status: promotionData.status,
      startTime: promotionData?.promotionDate?.startTime ? dayjs(promotionData?.promotionDate?.startTime).format('DD-MM-YY HH:mm:ss') : '',
      endTime: promotionData?.promotionDate?.endTime ? dayjs(promotionData?.promotionDate?.endTime).format('DD-MM-YY HH:mm:ss') : '',
      // startTime: promotionData?.promotionDate?.startTime ? dayjs(promotionData?.promotionDate?.startTime).valueOf() : '',
      // endTime: promotionData?.promotionDate?.endTime ? dayjs(promotionData?.promotionDate?.endTime).valueOf() : '',
      startDate: dateRange[0] ? moment(dateRange[0]).valueOf() : '',
      endDate: dateRange[1] ? moment(dateRange[1]).valueOf() : '',
      autoApply: promotionData?.promotionProperty?.autoApply === 'Yes' ? true : promotionData?.promotionProperty?.autoApply === 'No' ? false : '',
      isStackable: promotionData?.promotionProperty?.stackable === 'Yes' ? true : promotionData?.promotionProperty?.stackable === 'No' ? false : '',
      cashUpto: Number(promotionData.discount?.cashUpto)
    }

    if (data?.week?.length === 0) {
      delete data?.week
    }
    if (data?.week?.length > 0) {
      let weekObj = data.week?.reduce((prev, curr) => {
        return { ...prev, [curr]: true }
      }, {})
      data = {
        ...data,
        ...weekObj
      }
    }
    if (promotionId) {
      dispatch(updatePromotion({ data, promotionId })).unwrap().then(res => {
        navigate('/promotions')
        showResponseMessage(res?.message, 'success')
      }).catch(err => {
        showResponseMessage(err?.message, 'error')
      })

      return;
    }
    dispatch(createPromotion(data)).unwrap().then(res => {
      navigate('/promotions')
      showResponseMessage(res?.message, 'success')
    }).catch(err => {
      showResponseMessage(err?.message, 'error')
    })

  }


  const showResponseMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }

  useEffect(() => {
    if (promotionType === 'Product') {
      dispatch(fetchBrands({ skip: 0, limit: 100, userType: 'admin' }))
      dispatch(fetchCategories({ skip: 0, limit: 100, userType: 'admin' }))
      dispatch(fetchProducts({ filters: { skip: 0, limit: 1000, userType: 'admin' } }))
    }
  }, [promotionType])

  const promotionPayload = useMemo(() => {
    if (isEmptyObject(promotionDetail)) return {}
    let payload = {
      discount: {
        discountType: promotionDetail?.discountType,
        discountAmount: promotionDetail?.discountAmount,
        cashUpto: promotionDetail?.cashUpto
      },
      promotionAvailability: {
        "maxAvailablity": promotionDetail?.maxAvailablity,
        "maxAvailableCustomer": promotionDetail?.maxAvailableCustomer,
        maxAvailablity: promotionDetail?.maxAvailableCustomer ? 'Enabled' : 'Disabled',
        "availableUnits": promotionDetail?.availableUnits
      },
      promotionDate: {
        "date": "",
        "week": ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']?.filter((item => {
          if (promotionDetail[item]) {
            return item
          }
        })),
        // "startTime": createMomentDateWith24HourTime(promotionDetail?.startTime),
        // "endTime": promotionDetail?.endTime
        "startTime": promotionDetail?.startTime ? dayjs(parseDate(promotionDetail?.startTime)) : null,
        "endTime": promotionDetail?.endTime ? dayjs(parseDate(promotionDetail?.endTime)) : null,
      },
      promotionInfo: {
        "promoName": promotionDetail?.promoName,
        "promoCodes": promotionDetail?.promoCodes
      },
      promotionProperty: {
        "promotionType": promotionDetail?.promotionType,
        "autoApply": promotionDetail?.autoApply ? 'Yes' : "No",
        "stackable": promotionDetail?.isStackable ? 'Yes' : "No"
      },
      criteriaGroups: promotionDetail?.criteriaGroups || [],
      target: promotionDetail?.target || [],
      promoDescription: promotionDetail?.promoDescription,
      status: promotionDetail?.status

    }
    return payload;
  }, [promotionDetail])

  useEffect(() => {
    if (!isEmptyObject(promotionPayload)) {
      setPromotionData(promotionPayload)
    }
  }, [promotionPayload])

  useEffect(() => {
    if (!isEmptyObject(promotionDetail)) {
      setDateRange([promotionDetail?.startDate ? new Date(promotionDetail?.startDate) : null, promotionDetail?.endDate ? new Date(promotionDetail?.endDate) : null])
      if (promotionDetail?.promotionType === 'Cart') {
        dispatch(getPromotionCartRulesByType())
      }
      if (promotionDetail?.promotionType === 'Product') {
        dispatch(getPromotionProductRulesByType())
      }
      // if(promotionDetail?.promoCodes?.length > 0){
      //   setCodeGenerationCount(promotionDetail?.promoCodes?.length)
      // }
    }
  }, [promotionDetail])


  return (
    <Box p={{ xs: 2, sm: 3, lg: 4 }}>
      <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: "none" }}>
        <Stack direction={{ xs: "column", sm: "row" }} alignItems={{ sm: "center" }} justifyContent="space-between" gap={2} mb={4.5}>
          <Typography variant='h4' fontWeight="semibold" color="secondary">{promotionId ? 'Update' : 'Add'} Promotion</Typography>
          <Box display="flex" alignItems="center" gap={1.25}>
            <Typography variant='h5' fontWeight="semibold" color="secondary">Status</Typography>
            <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor="#F2F3F7">
              <MDButton
                sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                type='button'
                color={promotionData?.status ? 'success' : 'dark'}
                variant={promotionData?.status ? 'contained' : 'text'}
                size="small"
                onClick={() => setPromotionData({ ...promotionData, status: true })}
              >
                Active
              </MDButton>
              <MDButton
                sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                type='button'
                variant={!promotionData?.status ? 'contained' : 'text'}
                color={!promotionData?.status ? 'error' : 'dark'}
                size="small"
                onClick={() => setPromotionData({ ...promotionData, status: false })}
              >
                Inactive
              </MDButton>
            </Stack>
          </Box>
        </Stack>

        <Typography variant='h6' fontWeight="semibold" mb={1}>Promotion Information</Typography>
        <Grid container spacing={2.5} mb={3}>
          <Grid item xs={12} sm={4} xl={3}>
            <TextField error={Boolean(errors?.promotionName)} helperText={errors?.promotionName} className="form-input" fullWidth label="Promotion Name*" name="promotionName" value={promotionData?.promotionInfo?.promoName} onChange={(e) => promotionChangeHandler(e, 'promotionInfo', 'promoName')} />
          </Grid>
          <Grid item xs={12} sm={4} xl={3}>
            <TextField className="form-input" fullWidth label="Promo Generation Count" name="promotionName" value={codeGenerationCount} onChange={(e) => {
              if (e.target.value && !isNumber(e.target.value)) return;
              setCodeGenerationCount(e.target.value)
            }} />
          </Grid>
          <Grid item xs={12} sm={4} xl={3}>
            <TextField
              // InputProps={{
              //   endAdornment: <p>{promotionData?.promotionInfo?.promoCodes?.length > 0 ? promotionData?.promotionInfo?.promoCodes?.length : 0}</p>
              // }}
              error={Boolean(errors?.promoCodes)}
              helperText={errors?.promoCodes}
              onChange={(e) => {
                let promo = []
                if (e.target.value) {
                  promo = e.target.value?.split(',')
                }
                setPromotionData({ ...promotionData, promotionInfo: { ...promotionData?.promotionInfo, promoCodes: promo } })
              }}
              value={promotionData?.promotionInfo?.promoCodes?.join(',') || ''}
              className="form-input"
              fullWidth label="Promo Code*"
              name="promoCode" />
            <Typography variant='body2' fontWeight="medium" color="primary" mt={0.25} className='cur-pointer' onClick={() => {
              if (!codeGenerationCount) return showResponseMessage('Please enter the number of promo code to generate', 'error');
              if (+codeGenerationCount) {
                autoGenerateCode()
              }
            }}>Auto Generate Promo Code</Typography>
          </Grid>
        </Grid>

        <Typography variant='h6' fontWeight="semibold" mb={1}>Promotion Properties</Typography>
        <Grid container spacing={2.5} mb={4.5}>
          <Grid item xs={12} sm={4} xl={3}>
            <FormControl fullWidth>
              <InputLabel id="user-type-label">Promotion Type*</InputLabel>
              <Select
                className="form-select"
                labelId="user-type-label"
                id="user-type-select"
                label="Promotion Type*"
                name='promotionType'
                value={promotionData?.promotionProperty?.promotionType || ""}
                onChange={(e) => promotionChangeHandler(e, 'promotionProperty', 'promotionType')}
                error={Boolean(errors?.promotionType)}
              >
                <MenuItem value={'Cart'}>Cart</MenuItem>
                <MenuItem value={'Product'}>Product</MenuItem>
              </Select>
              {
                errors?.promotionType && <FormHelperText error>{errors?.promotionType}</FormHelperText>
              }
            </FormControl>
          </Grid>
          {
            promotionData?.promotionProperty?.promotionType === 'Cart' && <Grid item xs={12} sm={4} xl={3}>
              <FormControl fullWidth>
                <InputLabel id="user-type-label">Auto Apply?</InputLabel>
                <Select
                  className="form-select"
                  labelId="user-type-label"
                  id="user-type-select"
                  label="Auto Apply?"
                  name='autoApply'
                  value={promotionData?.promotionProperty?.autoApply || ""}
                  onChange={(e) => promotionChangeHandler(e, 'promotionProperty', 'autoApply')}
                >
                  <MenuItem value={'Yes'}>Yes</MenuItem>
                  <MenuItem value={'No'}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          }



          {/* <Grid item xs={12} sm={4} xl={3}>
            <FormControl fullWidth>
              <InputLabel id="user-type-label">Stackable</InputLabel>
              <Select
                className="form-select"
                labelId="user-type-label"
                id="user-type-select"
                label="Stackable"
                name='stackable'
                value={promotionData?.promotionProperty?.stackable || ""}
                onChange={(e) => promotionChangeHandler(e, 'promotionProperty', 'stackable')}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
        </Grid>


        <Typography variant='h6' fontWeight="semibold" mb={1}>Discount Type & Value</Typography>
        <Grid container spacing={2.5} mb={4.5}>
          <Grid item xs={12} sm={4} xl={3}>
            <FormControl fullWidth>
              <InputLabel id="user-type-label">Discount Type</InputLabel>
              <Select
                className="form-select"
                labelId="user-type-label"
                id="user-type-select"
                label="Discount Type"
                name='discountType'
                value={promotionData?.discount?.discountType || ""}
                onChange={(e) => promotionChangeHandler(e, 'discount', 'discountType')}
                error={Boolean(errors?.discountType)}

              >
                <MenuItem value={'Cash'}>Cash</MenuItem>
                <MenuItem value={'Percentage'}>Percentage</MenuItem>

              </Select>
              {
                errors?.discountType && <FormHelperText error>{errors?.discountType}</FormHelperText>
              }
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4} xl={3}>
            <TextField I
              InputProps={{
                startAdornment: promotionData?.discount?.discountType === 'Cash' ? "$" : "",
                endAdornment: promotionData?.discount?.discountType === 'Percentage' ? '%' : ""
              }}
              className="form-input currency-field"
              fullWidth
              label="Discount*"
              name="discount"
              value={promotionData?.discount?.discountAmount}
              onChange={(e) => promotionChangeHandler(e, 'discount', 'discountAmount')}
              error={Boolean(errors?.discountAmount)}
              helperText={errors?.discountAmount}
            />
          </Grid>
          {
            promotionData?.discount?.discountType === 'Percentage' && <Grid item xs={12} sm={4} xl={3}>
              <TextField
                InputProps={{
                  startAdornment: "$"
                }}
                className="form-input currency-field" fullWidth label="Discount Upto" name="cashUpto" value={promotionData?.discount?.cashUpto}
                onChange={(e) => {
                  if (e.target.value && !isValidNumber(e.target.value)) return;
                  promotionChangeHandler(e, 'discount', 'cashUpto')
                }}
                error={Boolean(errors?.cashUpto)}
                helperText={errors?.cashUpto}
              />
            </Grid>
          }
        </Grid>


        <Typography variant='h6' fontWeight="semibold" mb={1}>Promotion Date</Typography>
        <Grid container spacing={2.5} mb={4.5}>
          <Grid item xs={12} sm={6} xxl={3}>
            <DateRange setDateRange={setDateRange} dateRange={dateRange} />
          </Grid>
          <Grid item xs={12} sm={6} xxl={3}>
            <FormControl fullWidth>
              <InputLabel id="user-type-label">Available on (Weekdays)</InputLabel>
              <Select
                // endAdornment={
                //   <InputAdornment position="end">
                //     <IconButton>
                //       <KeyboardArrowDownOutlined />
                //     </IconButton>
                //   </InputAdornment>
                // }
                className="form-select days-form-select"
                labelId="user-type-label"
                id="user-type-select"
                label="Available on (Weekdays)"
                name='week'
                value={promotionData?.promotionDate?.week || []}
                multiple

                onChange={(e) => promotionChangeHandler(e, 'promotionDate', 'week')}

              >
                <MenuItem value={'mon'}>Mon</MenuItem>
                <MenuItem value={'tue'}>Tue</MenuItem>
                <MenuItem value={'wed'}>Wed</MenuItem>
                <MenuItem value={'thu'}>Thu</MenuItem>
                <MenuItem value={'fri'}>Fri</MenuItem>
                <MenuItem value={'sat'}>Sat</MenuItem>
                <MenuItem value={'sun'}>Sun</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} xxl={3}>
            <FormControl fullWidth>
              <MobileTimePicker
                className="mobile-time-picker"
                label="Start time"
                value={promotionData?.promotionDate?.startTime}
                onChange={(e) => setPromotionData({ ...promotionData, promotionDate: { ...promotionData?.promotionDate, startTime: e } })}
                format='HH:mm'
                ampm
                renderInput={(params) =>
                  <TextField {...params}
                  // error={Boolean(errors.closingHours)}
                  // helperText={errors.closingHours}
                  />}
              // ampm={false}
              />
              {/* {Boolean(errors.openingHours) && <FormHelperText error>{errors.openingHours}</FormHelperText>} */}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} xxl={3}>
            <FormControl fullWidth>
              <MobileTimePicker
                className="mobile-time-picker"
                label="End time"
                value={promotionData?.promotionDate?.endTime}
                onChange={(e) => setPromotionData({ ...promotionData, promotionDate: { ...promotionData?.promotionDate, endTime: e } })}
                format='HH:mm'
                ampm
                renderInput={(params) =>
                  <TextField {...params}
                  // error={Boolean(errors.closingHours)}
                  // helperText={errors.closingHours}
                  />}
              // ampm={false}
              />
              {/* {Boolean(errors.closingHours) && <FormHelperText error>{errors.closingHours}</FormHelperText>} */}
            </FormControl>
          </Grid>
        </Grid>

        <Typography variant='h6' fontWeight="semibold" mb={1}>Promotion Availability</Typography>
        <Grid container spacing={2.5} mb={4.5}>
          <Grid item xs={12} sm={4} xl={3}>
            <TextField className="form-input" fullWidth label="Available Units" name="availableUnits"
              value={promotionData?.promotionAvailability?.availableUnits || ""}
              onChange={(e) => {
                if (e.target.value && !(/^[1-9][0-9]*$/.test(e.target.value))) return;
                promotionChangeHandler(e, 'promotionAvailability', 'availableUnits')
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} xl={3}>
            <FormControl fullWidth>
              <InputLabel id="user-type-label">Max Availability</InputLabel>
              <Select
                className="form-select"
                labelId="user-type-label"
                id="user-type-select"
                label="Max Availability"
                name='maxAvailability'
                value={promotionData?.promotionAvailability?.maxAvailablity || ""}
                onChange={(e) => promotionChangeHandler(e, 'promotionAvailability', 'maxAvailablity')}
              >
                <MenuItem value={'Enabled'}>Enabled</MenuItem>
                <MenuItem value={'Disabled'}>Disabled</MenuItem>

              </Select>
            </FormControl>
          </Grid>
          {
            promotionData?.promotionAvailability?.maxAvailablity === 'Enabled' && <Grid item xs={12} sm={4} xl={3}>
              <TextField className="form-input" fullWidth label="Max Per Customer" name="MaxPerCustomer" value={promotionData?.promotionAvailability?.maxAvailableCustomer || ""}
                onChange={(e) => promotionChangeHandler(e, 'promotionAvailability', 'maxAvailableCustomer')} />
            </Grid>
          }

        </Grid>

        <Typography variant='h6' fontWeight="semibold" mb={1}>Promo Description</Typography>
        <Grid container spacing={2.5} mb={4.5}>
          <Grid item xs={12}>
            <TextField className="form-input" fullWidth label="Add Description..." name="AddDescription" value={promotionData?.promoDescription} onChange={(e) => promotionChangeHandler(e, 'promoDescription', '')} />
          </Grid>
        </Grid>
        {
          promotionType === 'Cart' ? <>
            <Typography variant='h6' fontWeight="semibold" mb={1}>Promotions Rules</Typography>
            {
              promotionData?.criteriaGroups?.map((productRule, index) => {
                return <><Box bgcolor="#F2F3F7" borderRadius={2.5} mb={1.75} p={1.75}>
                  <Grid container key={index} spacing={2}>
                    <Grid item xs={12} md={4.5} xl={5}>
                      <FormControl fullWidth>
                        <InputLabel id="user-type-label">Cart Rule</InputLabel>
                        <Select
                          className="form-select"
                          labelId="user-type-label"
                          id="user-type-select"
                          label="Cart Rule"
                          name='ruleType'
                          value={productRule?.ruleType || ''}
                          onChange={(e) => cartRuleChangeHandler(e, index)}
                        >
                          {
                            productRules?.map(((rule, idx) => {
                              return <MenuItem key={idx} value={rule}>{rule}</MenuItem>
                            }))
                          }
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4.5} xl={5}>
                      {
                        (productRule?.ruleType === 'Number Minimum Orders' || productRule?.ruleType === 'Cart Order Minimum') &&
                        <TextField InputProps={{
                          startAdornment: productRule?.ruleType === 'Cart Order Minimum' ? '$' : ''
                        }}
                          className="form-input currency-field"
                          name='minAmt' fullWidth label={productRule?.ruleType}
                          value={productRule?.minAmt || ''}
                          onChange={(e) => cartRuleChangeHandler(e, index)} />
                      }
                    </Grid>

                    <Grid item xs={12} md={3} xl={2} alignContent="center">
                      <Box width="100%" display="inline-flex" alignItems="center" justifyContent="end" gap={2}>
                        <IconButton onClick={() => deleteCartRuleHandler(index)} color='error' size='small' variant="primary">
                          <DeleteIcon width='16px' height='16px' />
                        </IconButton>
                      </Box>

                    </Grid>

                  </Grid>
                </Box></>
              })
            }
          </> : promotionType === 'Product' ? <>
            <Typography variant='h6' fontWeight="semibold" mb={1}>Promotions Rules</Typography>

            <Box>
              {/* <Grid container spacing={2.5}> */}
              {
                target?.map(((productRule, idx) => {
                  return <><Box bgcolor="#F2F3F7" borderRadius={2.5} mb={1.75} p={1.75}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={12} md={4.5} xl={5}>
                        <FormControl fullWidth>
                          <InputLabel id="user-type-label">Product Rule</InputLabel>
                          <Select
                            className="form-select"
                            labelId="user-type-label"
                            id="user-type-select"
                            label="Product Rule"
                            name='ruleType'
                            value={productRule?.ruleType || ''}
                            onChange={(e) => productRuleChangeHandler(e, idx)}
                          >
                            {
                              productRules?.map(((rule, idx) => {
                                return <MenuItem key={idx} value={rule}>{rule}</MenuItem>
                              }))
                            }
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={4.5} xl={5}>
                        {productRule?.ruleType === 'By Brand' &&
                          <Autocomplete
                            multiple
                            id="tags-outlined"
                            className='cur-pointer'
                            options={brandsData?.brands || []}
                            getOptionLabel={(option) => option.brandName}
                            filterSelectedOptions
                            onChange={(val, selectedOption, details) => {
                              productAutoCompletChangeHandler(selectedOption, 'brandIds', idx)
                            }}
                            // onChange={}
                            value={productRule?.brandIds || []}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Brands"
                              />
                            )}
                          />
                        }

                        {productRule?.ruleType === 'By Product Category' &&
                          <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={categoriesData?.categories || []}
                            getOptionLabel={(option) => option.categoryName}
                            filterSelectedOptions
                            onChange={(val, selectedOption, details) => {
                              productAutoCompletChangeHandler(selectedOption, 'categoryIds', idx)
                            }}
                            // onChange={}
                            value={productRule?.categoryIds || []}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Categories"
                              />
                            )}
                          />
                        }

                        {productRule?.ruleType === 'By Product' &&
                          <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={products?.filteredProducts || []}
                            getOptionLabel={(option) => option.productName}
                            filterSelectedOptions
                            onChange={(val, selectedOption, details) => {
                              productAutoCompletChangeHandler(selectedOption, 'productIds', idx)
                            }}
                            // onChange={}
                            value={productRule?.productIds || []}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Products"
                              />
                            )}
                          />
                        }
                      </Grid>

                      <Grid item xs={12} md={3} xl={2} alignContent="center">

                        <Box width="100%" display="inline-flex" alignItems="center" justifyContent="end" gap={2}>
                          <IconButton onClick={() => deleteProductRuleHandler(idx)} color='error' size='small' variant="primary">
                            <DeleteIcon width='16px' height='16px' />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box></>
                }))
              }

              {/* </Grid> */}
            </Box>
          </> : null
        }


        {
          promotionType && <Box>
            <MDButton
              sx={{ minWidth: "140px" }}
              type='button'
              variant='outlined'
              color='primary'
              onClick={promotionType === 'Product' ? addProductRule : promotionType === 'Cart' ? addNewCartRule : null}
            >
              + Add Rule
            </MDButton>
          </Box>
        }


        <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25} mt={7} mb={2}>
          <MDButton
            sx={{ minWidth: "140px" }}
            type='button'
            variant='contained'
            color='primary'
            onClick={submitHandler}
            disabled={isLoading}
            loading={isLoading}
          >
            Save
          </MDButton>

          {/* <MDButton
            sx={{ minWidth: "140px", backgroundColor: "#F1F1F1", color: "#FD4438" }}
            type='button'
            variant='contained'
            color='error'
          >
            Delete
          </MDButton> */}

          <MDButton
            sx={{ minWidth: "140px" }}
            type='button'
            variant='outlined'
            color='dark'
            onClick={() => navigate('/promotions')}
          >
            Cancel
          </MDButton>
        </Stack>
      </Card>
    </Box>
  )
}
