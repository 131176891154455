
import React, { useRef, useEffect } from 'react';

const CalculateMap = ({ route, routeMap }) => {
  const mapRef = useRef(null);
  const platform = useRef(null);
  const mapInstance = useRef(null);

  useEffect(() => {
    if (routeMap?.length == 0 || routeMap == undefined) {
      return

    }
    else {
      const updatedPoints = routeMap?.map(point => ({
        lat: point?.latitude,
        lng: point?.longitude
      }));

      const apikey = process.env.REACT_APP_MAP_KEY;
      platform.current = new H.service.Platform({ 'apikey': apikey });
      const rasterTileService = platform.current.getRasterTileService({
        queryParams: {
          'style': 'logistics.night',
          'features': 'vehicle_restrictions:active_and_inactive,pois:disabled',
          'size': 512
        }
      });
      const rasterTileProvider = new H.service.rasterTile.Provider(rasterTileService);
      const rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider);

      const defaultLayers = platform.current.createDefaultLayers();

      mapInstance.current = new H.Map(
        mapRef.current,
        rasterTileLayer,
        {
          zoom: 50,
          center: { lat: updatedPoints?.[0]?.lat, lng: updatedPoints?.[0]?.lng },
          center: { lat: 30.6813832, lng: 76.7266839 },
          pixelRatio: window.devicePixelRatio || 1
        }
      );

      const ui = H.ui.UI.createDefault(mapInstance.current, defaultLayers); // icons display zoom in out

      // left corner icon
      const overviewMap = new H.ui.Overview(defaultLayers.raster.satellite.map, {
        alignment: H.ui.LayoutAlignment.LEFT_BOTTOM,
        zoomDelta: 6,
        scaleX: 5,
        scaleY: 6,
      });
      ui.addControl('overview', overviewMap);
      const origin = updatedPoints?.[0]
      const destination = updatedPoints?.[updatedPoints?.length - 1];
      // const origin = { lat: 30.6813832, lng: 76.7266839 }
      // const destination = { lat: 30.6813854, lng: 76.7266857 }
      const routingParameters = {
        'routingMode': 'fast',
        'transportMode': 'car',
        'origin': `${origin?.lat},${origin?.lng}`,
        'destination': `${destination?.lat},${destination?.lng}`,
        'return': 'polyline',
        'via': new H.service.Url.MultiValueQueryParameter(
          updatedPoints?.map(wp => `${wp?.lat},${wp?.lng}`)
        )
      };

      const onResult = (result) => {
        if (result.routes.length) {
          const lineStrings = [];
          result.routes[0].sections.forEach((section) => {
            lineStrings.push(H.geo.LineString.fromFlexiblePolyline(section.polyline));
          });

          const multiLineString = new H.geo.MultiLineString(lineStrings);
          const routeLine = new H.map.Polyline(multiLineString, {
            style: { strokeColor: 'yellow', lineWidth: 3 }
          });
          const createCustomIcon = (color) => {
            return new H.map.Icon(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="10" fill="${color}" />
            </svg>`, { size: { w: 24, h: 24 } });
          };

          const startMarker = new H.map.Marker(origin, { icon: createCustomIcon('yellow') });
          const endMarker = new H.map.Marker(destination, { icon: createCustomIcon('yellow') });

          const group = new H.map.Group();
          group.addObjects([routeLine, startMarker, endMarker]);
          mapInstance.current.addObject(group);

          mapInstance.current.getViewModel().setLookAtData({
            bounds: group.getBoundingBox(),
          });
        }
      };
      const router = platform.current.getRoutingService(null, 8);
      router.calculateRoute(routingParameters, onResult, (error) => {
        alert(error.message);
      });

      // Prepare data for the heat map
      const heatMapData = [];
      // Create a provider for the heat map
      const heatmapProvider = new H.data.heatmap.Provider({
        colors: new H.data.heatmap.Colors({
          '0': 'blue',
          '0.5': 'yellow',
          '1': 'red'
        }, true),
        opacity: 0.1,
        assumeValues: true
      });

      // Add the data to the heatmap provider
      heatmapProvider.addData(heatMapData);

      // Add a layer for the heat map provider to the map
      mapInstance.current.addLayer(new H.map.layer.TileLayer(heatmapProvider));

      new H.mapevents.Behavior(new H.mapevents.MapEvents(mapInstance.current));

      window.addEventListener('resize', () => mapInstance.current.getViewPort().resize());

      return () => {
        window.removeEventListener('resize', () => mapInstance.current.getViewPort().resize());
      };

    }
  }, [routeMap]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%', borderRadius: '6px', overflow: 'hidden' }}>
      {
        (routeMap?.length == 0 || routeMap == undefined) ? "" : <div ref={mapRef} style={{ width: '100%', height: route ? '200px' : '300px' }}></div>
      }
    </div>
  );
};

export default CalculateMap;