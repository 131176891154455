import { Close } from '@mui/icons-material'
import { Box, Button,  FormControl, FormControlLabel, FormHelperText,  Grid, Menu, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import moment from 'moment'
import ReassignDriverIcon from 'common/customIcons/reassigndriver'
import MapDriveListDrawer from './MapDriveListDrawer';
import { addStartTask } from '../../../redux/slices/hyperdrive/createTask';
import { openSnackbar } from '../../../redux/slices/common';

export default function BreakTask({ closeModal }) {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({})
    const [openingHours, setOpeningHours] = useState(null);
    const [closingHours, setClosingHours] = useState(null);
    const [sendBreakAt, setSendBreakAt] = useState(null);
    const [formData, setFormData] = useState({
        taskName: "",
        taskAssignmentMode: "automatic",
        taskType: "breakTask",
        fleetId: "",
        timezone: "America/Los_Angeles",
        fromTime: "",
        toTime: "",
        sendBreakAt: ""
    })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showManualList, setShowManualList] = useState(false);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

       const handleOpeningHoursChange = (newTime) => {
        setOpeningHours(newTime);
        const date = new Date(newTime)
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const formattedTime = `${hours}:${minutes}`;
        setFormData(prevData => ({ ...prevData, fromTime: formattedTime }))
    }



    const handleClosingHoursChange = (newTime) => {
        setClosingHours(newTime);
        const date = new Date(newTime)
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const formattedTime = `${hours}:${minutes}`;
        setFormData(prevData => ({ ...prevData, toTime: formattedTime }))
    };

    const handleSendBreakAtChange = (newTime) => {
        setSendBreakAt(newTime);
        const date = new Date(newTime)
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const formattedTime = `${hours}:${minutes}`;
        setFormData(prevData => ({ ...prevData, sendBreakAt: formattedTime }))
    };

    const startTaskHandler = (event) => {
        const { name, value } = event.target;
        const formatDateString = (dateString) => {
            const date = moment(dateString);
            if (!date.isValid()) {
                return '';
            }
            return date.format('YYYY-MM-DD HH:mm:ss');
        };
        const formattedValue = (name === 'taskAssignmentMode') ? value : (name === 'taskStartTime' || name === 'taskEndTime') ? formatDateString(value) : value; setFormData(prevData => {
            const newData = {
                ...prevData,
                [name]: formattedValue
            };

            if (name === 'taskAssignmentMode') {
                if (value === "manual") {
                    setShowManualList(true)
                }
                else {
                    setErrors({ fleetId: "" })
                    setShowManualList(false)
                }
            }
            const startDate = moment(newData.taskStartTime);
            const endDate = moment(newData.taskEndTime);
            if (name === 'taskStartTime' && newData.taskEndTime) {
                if (endDate.isBefore(startDate)) {
                    setErrors(prevData => ({ ...prevData, taskStartTime: "Start date must be before the end date." }))
                    // alert('Start date must be before the end date.');
                    return prevData;
                }
            }
            if (name === 'taskEndTime' && newData.taskStartTime) {
                if (endDate.isBefore(startDate)) {
                    setErrors(prevData => ({ ...prevData, taskEndTime: "End date must be after the start date." }))
                    // alert('End date must be after the start date.');
                    return prevData;
                }
            }
            setErrors({ taskStartTime: "", taskEndTime: "" })
            return newData;
        });
    };

    const validateTask = () => {
        let isError = false
        let errors = {}
        if (!formData?.taskName?.trim()) {
            isError = true
            errors = { ...errors, taskName: 'Break Name is required' }
        }
        if (!openingHours) {
            isError = true
            errors = { ...errors, openingHours: 'Break Start Time Name is required' }
        }
        if (!sendBreakAt) {
            isError = true
            errors = { ...errors, sendBreakAt: 'Send Break At Name is required' }
        }
        if (!closingHours) {
            isError = true
            errors = { ...errors, closingHours: 'Break End Time Name is required' }
        }
        return { errors, isError }
    }

    const submitHandler = () => {
        const validationResult = validateTask();
        let isError = validationResult.isError;
        let errors = { ...validationResult.errors };
        setErrors(errors);
        if (isError) return;
        if (formData?.taskAssignmentMode === "manual") {
            if (!formData?.fleetId?.trim()) {
                isError = true;
                errors = { ...errors, fleetId: 'Fleet ID is required' };
                setErrors(errors);
                return false
            }
        }

        dispatch(addStartTask({ data: formData }))
            .unwrap()
            .then(res => {
                showResponseMessage(res?.message, 'success');
                closeModal()
            })
            .catch(err => {
                showResponseMessage(err?.message, 'error');
            });
    }

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    function handleDataFromChild() {
        setShowManualList(false)
    }

    const handleFleetValueChange = (value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            fleetId: value
        }));
    };

    return (
        <Box className="cus-wrapper-drawer">
            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} style={{ borderBottom: "1px solid  #1E1E24" }} className="drawer-head-sec">
                <Typography className="text-14 text-white-700">Create Break Task</Typography>
                <Close onClick={closeModal} style={{ cursor: "pointer", color: "#ffffff" }} />
            </Box>

            <Box className="drawer-scroll-sec">
                <Box px={2} py={4}>

                    <Stack direction='column' mb={4}>
                        <Typography mb={1} fontSize={22} fontWeight={600} className='text-white-700'>Break Name</Typography>
                        <Stack direction='column' gap={2} mb={1} className='dark-field'>
                            <TextField
                                fullWidth
                                helperText={errors.taskName}
                                error={errors?.taskName}
                                label={'Break Name'}
                                name="taskName"
                                onChange={(e) => startTaskHandler(e)}
                                className="form-input"
                                value={formData?.taskName || ''}
                            />
                        </Stack>
                    </Stack>

                    <Stack direction='column' mb={4}>
                        <Typography mb={1} fontSize={22} fontWeight={600} className='text-white-700'>Break Details</Typography>

                        <Grid container spacing={2} className='dark-field'>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <MobileTimePicker
                                        className="mobile-time-picker"
                                        label="Break Start Time"
                                        value={openingHours}
                                        onChange={handleOpeningHoursChange}
                                        ampm={false}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            error={Boolean(errors.openingHours)}
                                            helperText={errors.openingHours}
                                        />}
                                    />
                                    {Boolean(errors.openingHours) && <FormHelperText error>{errors.openingHours}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <MobileTimePicker
                                        className="mobile-time-picker"
                                        label="Break End Time"
                                        value={closingHours}
                                        onChange={handleClosingHoursChange}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                error={Boolean(errors.closingHours)}
                                                helperText={errors.closingHours}
                                            />}
                                        ampm={false}
                                    />
                                    {Boolean(errors.closingHours) && <FormHelperText error>{errors.closingHours}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    helperText={errors.timezone}
                                    error={errors?.timezone}
                                    label={'Timezone'}
                                    name="timezone"
                                    onChange={(e) => startTaskHandler(e)}
                                    className="form-input"
                                    value={formData?.timezone || ''}
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <MobileTimePicker
                                        className="mobile-time-picker"
                                        label="Send Break At"
                                        value={sendBreakAt}
                                        onChange={handleSendBreakAtChange}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                error={Boolean(errors.sendBreakAt)}
                                                helperText={errors.sendBreakAt}
                                            />}
                                        ampm={false}
                                    />
                                    {Boolean(errors.sendBreakAt) && <FormHelperText error>{errors.sendBreakAt}</FormHelperText>}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Stack>

                    <Stack>
                        <div className='d-flex reassign-driver-btn'>
                            <Button
                                id=""
                                aria-controls={open ? '' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                className='d-flex gap-2 text-white-900'
                            >
                                <ReassignDriverIcon />
                                <Typography fontWeight="semibold" className='text-12 text-white-900 text-transform-none'>Reassign Driver</Typography>
                                <KeyboardArrowDownIcon />
                                <span style={{ color: "red" }}>{errors?.fleetId}</span>
                            </Button>
                            <Menu
                                id=""
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{ className: "hd-dropdown reassign-driver-dropdown" }}
                            >
                                <FormControl>
                                    <RadioGroup
                                        value={formData?.taskAssignmentMode}
                                        aria-labelledby=""
                                        defaultValue="automatic"
                                        name="taskAssignmentMode"
                                        className='radio-colors'
                                        onChange={(e) => startTaskHandler(e)}
                                    >
                                        <FormControlLabel className='field-off-duty' value="manual" control={<Radio />} label="Manual" />
                                        <FormControlLabel className='field-idle' value="automatic" control={<Radio />} label="Automatic" />                                </RadioGroup>
                                </FormControl>
                            </Menu>
                        </div>
                    </Stack>

                </Box>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} gap={2} style={{ borderTop: "1px solid  #1E1E24" }} className="drawer-bottom-btns">
                <Button
                    variant="outlined"
                    color="secondary"
                    className='d-flex align-item-center gap-2'
                    onClick={closeModal}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className='d-flex align-item-center gap-2 green-btn'
                    onClick={() => submitHandler()}
                >
                    Create Task
                </Button>
            </Box>

            {showManualList &&
                <MapDriveListDrawer sendDataToParent={handleDataFromChild} onValueChange={handleFleetValueChange} />
            }

        </Box>
    )
}

