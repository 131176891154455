import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addTaskArchiveApi, fetchAllDriversLocationsApi, fetchArchiveTaskTableApi, fetchDriverNameApi, fetchRouteTableApi, fetchTaskDetailsApi, fetchTaskTableApi, tableArchiveExportApi, tableUnarchiveExportApi } from '../../../redux/apis/hyperdrive/drivers';

const initialState = {
  driversLocation: [],
  loading: false,
  error: null,
  flavors: [],
  getTaskDrive: {}
};

export const fetchAllDriversLocations = createAsyncThunk(
  'common/fetchAllDriversLocations',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fetchAllDriversLocationsApi(data);
      return res;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchTaskDetails = createAsyncThunk(
  'common/fetchTaskDetails',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fetchTaskDetailsApi(data);
      return res;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);


export const fetchTaskTable = createAsyncThunk(
  'common/fetchTaskTable',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await fetchTaskTableApi(data);
      return res;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchArchiveTaskTable = createAsyncThunk(
  'common/fetchTaskTable',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await fetchArchiveTaskTableApi(data);
      return res;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);
export const addTaskArchive = createAsyncThunk(
  'common/addTaskArchive',
  async ({ data }, { rejectWithValue }) => {
    console.log("taskId", data);
    try {
      const res = await addTaskArchiveApi(data);
      return res;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);
export const fetchRouteTable = createAsyncThunk(
  'common/fetchRouteTable',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await fetchRouteTableApi(data);
      return res;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchDriverName = createAsyncThunk(
  'common/fetchDriverName',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fetchDriverNameApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
export const tableUnarchiveExport = createAsyncThunk(
  'common/tableUnarchiveExport',
  async (data, { rejectWithValue }) => {
    try {
      const res = await tableUnarchiveExportApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const tableArchiveExport = createAsyncThunk(
  'common/tableArchiveExport',
  async (data, { rejectWithValue }) => {
    try {
      const res = await tableArchiveExportApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);


const driversSlice = createSlice({
  name: 'drivers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDriversLocations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllDriversLocations.fulfilled, (state, action) => {
        state.loading = false;
        state.driversLocation = action.payload;
      })
      .addCase(fetchAllDriversLocations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(fetchTaskTable.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTaskTable.fulfilled, (state, action) => {
        state.loading = false;
        state.taskTable = action.payload;
      })
      .addCase(fetchTaskTable.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })


    builder
      .addCase(fetchTaskDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTaskDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.taskDetails = action.payload;
      })
      .addCase(fetchTaskDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(fetchRouteTable.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRouteTable.fulfilled, (state, action) => {
        state.loading = false;
        state.routeTable = action.payload;
      })
      .addCase(fetchRouteTable.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(fetchDriverName.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDriverName.fulfilled, (state, action) => {
        state.loading = false;
        state.driverName = action.payload;
      })
      .addCase(fetchDriverName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(tableUnarchiveExport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(tableUnarchiveExport.fulfilled, (state, action) => {
        state.loading = false;
        state.tableUnarchive = action.payload;
      })
      .addCase(tableUnarchiveExport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      builder
      .addCase(tableArchiveExport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(tableArchiveExport.fulfilled, (state, action) => {
        state.loading = false;
        state.tableArchive = action.payload;
      })
      .addCase(tableArchiveExport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

  },
});

export const { } = driversSlice.actions;

export default driversSlice.reducer;