import React from 'react'
import { Button, Typography } from "@mui/material";
import AccessDenied from '../../assets/images/access-denied.svg';
import { useNavigate } from 'react-router-dom';
import { getData } from 'utilities/common';

export default function PageNotFound() {
  const navigate = useNavigate()
  let localUser = getData('login-user-info')
  if (localUser) {
    localUser = JSON.parse(localUser)
  }
  return (
    <div className="access-denied-page">
      <div className="access-denied-inner">
        <img src={AccessDenied} />
        <Typography variant='h1'>Access Denied</Typography>
        <Typography>You do not have permission to access this page. Please contact your administrator.</Typography>
        <Button onClick={()=>navigate(localUser?.userRoles?.includes('Super Admin') || localUser?.userRoles?.includes('Developer') ? '/dashboard' : localUser?.userRoles?.includes('Sales Associate') || localUser?.userRoles?.includes('Customer Support') ? '/orders?orderFilter=' : localUser?.userRoles?.includes('Sales Associate') || localUser?.userRoles?.includes('Developer') ? '/dashboard' : '/products')} size="medium" variant="contained" color="primary">Back to home</Button>
      </div>
    </div>
  )
}
