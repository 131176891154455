import React, { useEffect, useState } from 'react'
import { Card, Grid, Icon, Typography, Table, Box, IconButton, Stack, TextField, Switch } from '@mui/material'
import MDBox from 'components/MDBox'
import iconCar from '../../../../assets/images/icon-car.svg';
import iconBicycle from '../../../../assets/images/icon-bicycle.svg';
import iconBike from '../../../../assets/images/icon-bike.svg';
import { getTransportation, updateTransportStatus } from '../../../../redux/slices/hyperdrive/setting';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../../../redux/slices/common';

export default function Transportation() {
    const dispatch = useDispatch()
    const { mainTransportData } = useSelector(state => state?.commonHyperdrive)

    useEffect(() => {
        dispatch(getTransportation())
    }, [])

    const statusChangeTransport = (e, value, transportId) => {
        const payload = { isActive: value }

        dispatch(updateTransportStatus({ data: payload, transportId }))
            .unwrap()
            .then(res => {
                dispatch(getTransportation())
                showResponseMessage(res?.message, 'success');
            })
            .catch(err => {
                showResponseMessage(err?.message, 'error');
            });
    };
    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }


    return (
        <Card sx={{ pt: 4, pb: 3, px: 4, mb: 4, borderRadius: 0 }}>
            <MDBox display="flex" alignItems="center" mb={2} mt={1}>
                <MDBox>
                    <Typography variant='h5' fontWeight="semibold" color="#F5F5FF">Transportation</Typography>
                </MDBox>
            </MDBox>

            <Typography color="#A9A9B7" mb={3} className='text-14'>
                Consectetur accumsan nibh ex sollicitudin metus volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis. Vestibulum fringilla felis nec turpis aliquam auctor a a lectusetiam porttitor at eros vitae posuere.
            </Typography>

            <Grid container alignItems="center" spacing={{ xs: 3, md: 3 }} mb={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Table className='hw-table'>
                        <thead>
                            <tr>
                                <th>
                                    <Typography className='text-black-400 text-12 text-left'>Vehicle</Typography>
                                </th>
                                <th>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                mainTransportData?.transportations?.map((transportData) => {
                                    return (
                                        <tr key={transportData?._id}>
                                            <td>
                                                <Typography className='d-flex align-item-center gap-2 text-white-700 text-14'>
                                                    <img src={transportData?.imageUrl} />
                                                    {transportData?.name}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Box display="flex" alignItems="center" justifyContent="end">
                                                    <Switch checked={transportData?.isActive} onChange={(e, value) => statusChangeTransport(e, value, transportData?._id)} />
                                                </Box>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {/* <tr>
                                <td>
                                    <Typography className='d-flex align-item-center gap-2 text-white-700 text-14'>
                                        <img src={iconCar} />
                                        Car
                                    </Typography>
                                </td>
                                <td>
                                    <Box display="flex" alignItems="center" justifyContent="end">
                                        <Switch onChange={(e, value) => statusChangeTransport(e, value, "1")} />
                                    </Box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography className='d-flex align-item-center gap-2 text-white-700 text-14'>
                                        <img src={iconBike} />
                                        Bike
                                    </Typography>
                                </td>
                                <td>
                                    <Box display="flex" alignItems="center" justifyContent="end">
                                        <Switch />
                                    </Box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography className='d-flex align-item-center gap-2 text-white-700 text-14'>
                                        <img src={iconBicycle} />
                                        Bicycle
                                    </Typography>
                                </td>
                                <td>
                                    <Box display="flex" alignItems="center" justifyContent="end">
                                        <Switch />
                                    </Box>
                                </td>
                            </tr> */}
                        </tbody>
                    </Table>
                </Grid>
            </Grid>
        </Card>
    )
}