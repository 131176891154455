import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { removeData } from 'utilities/common';
import { setData } from 'utilities/common';
import { forgotPasswordAPI, getUserInfoAPI, hyperWolfUserLoginAPI, resetPasswordAPI, userLoginAPI, userLogoutAPI, userSignupAPI } from '../../redux/apis/auth';


export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async (data, { rejectWithValue }) => {
        try {
            const res = await userLoginAPI(data);
            return res;
        } catch (error) {
            console.log("error444", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);

export const loginHyperwolfUser = createAsyncThunk(
    'auth/loginHyperwolfUser',
    async (data, { rejectWithValue }) => {
        try {
            const res = await hyperWolfUserLoginAPI(data);
            return res;
        } catch (error) {
            console.log("error444", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);


export const forgotPassword = createAsyncThunk(
    'auth/forgotPassword',
    async (data, { rejectWithValue }) => {
        try {
            const res = await forgotPasswordAPI(data);
            return res;
        } catch (error) {
            console.log("error444", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);

export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async (data, { rejectWithValue }) => {
        try {
            const res = await resetPasswordAPI(data);
            return res;
        } catch (error) {
            console.log("error444", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);


export const signupUser = createAsyncThunk(
    'auth/signupUser',
    async (data, { rejectWithValue }) => {
        try {
            const res = await userSignupAPI(data);
            return res;
        } catch (error) {
            console.log("error444", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);

export const getUserInfo = createAsyncThunk(
    'auth/getUserInfo',
    async (userId, { rejectWithValue }) => {
        try {
            const res = await getUserInfoAPI(userId);
            return res?.adminData;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);

export const logoutUserData = createAsyncThunk(
    'auth/logoutUser',
    async (data, { rejectWithValue }) => {
        try {
            const res = await userLogoutAPI(data);
            return res
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const msalSlice = createSlice({
    name: 'auth',
    initialState: {
        account: null,
        isAuthenticated: false,
        logging: false,
        user: {}
    },
    reducers: {
        setAccount: (state, action) => {
            state.user = action.payload;
            state.isAuthenticated = !!action.payload;
        },
        logoutUser: (state, action) => {
            state.user = {};
            state.isAuthenticated = false;
            removeData('login-user-info')
            removeData('firebaseDeviceToken')
            window.location.href = '/login'
        },
        setUserInfo: (state, action) => {
            state.user = action.payload;
            state.isAuthenticated = true;
            setData('login-user-info', action.payload)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.logging = true;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.logging = false
                state.isAuthenticated = true
                setData('login-user-info', action.payload)
                state.user = action.payload;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.logging = false
                state.error = action.payload;
            });
        builder
            .addCase(loginHyperwolfUser.pending, (state) => {
                state.logging = true;
            })
            .addCase(loginHyperwolfUser.fulfilled, (state, action) => {
                state.logging = false
                state.isAuthenticated = true
                setData('login-user-info', action.payload)
                state.user = action.payload;
            })
            .addCase(loginHyperwolfUser.rejected, (state, action) => {
                state.logging = false
                state.error = action.payload;
            });
        builder
            .addCase(signupUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(signupUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
            })
            .addCase(signupUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload; // action.payload is the rejected value
            });

        builder
            .addCase(forgotPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(forgotPassword.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.loading = false

            });
        builder
            .addCase(resetPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.loading = false

            });

        builder
            .addCase(getUserInfo.fulfilled, (state, action) => {
                state.logging = false
                state.isAuthenticated = true
                setData('login-user-info', action.payload)
                state.user = action.payload;
            })

        builder
            .addCase(logoutUserData.pending, (state) => {
                state.logging = true;
            })
            .addCase(logoutUserData.fulfilled, (state) => {
                state.logging = false;
                state.user = {};
                state.isAuthenticated = false;
                removeData('login-user-info');
                removeData('firebaseDeviceToken');
                window.location.href = '/login';
            })
            .addCase(logoutUserData.rejected, (state, action) => {
                state.logging = false;
                state.error = action.payload;
            });


    },
});

export const { setAccount, logoutUser } = msalSlice.actions;

export const selectAccount = (state) => state.auth.user;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;

export default msalSlice.reducer;