import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Typography, List, ListItem, FormControl, FormControlLabel, Radio, RadioGroup, Box } from "@mui/material";
import driverPic from "../../../assets/images/driver-pic.svg"
import { useDispatch } from 'react-redux';
import EastIcon from '@mui/icons-material/East';
import { fetchDrivers } from '../../../redux/slices/hyperdrive/driverAnalytics';
import { useSelector } from 'react-redux';

const MapDriveListDrawer = ({ sendDataToParent = () => { }, onValueChange }) => {
    const dispatch = useDispatch();
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();
    const searchTermRef = useRef("")
    const { driverList = {} } = useSelector(state => state.driverAnalyticsHyperDrive)

    // useEffect(() => {
    //     dispatch(fetchDrivers())
    // }, [])

    const loadMorePosts = useCallback(async (data) => {
        setLoading(true);
        try {
            const limit = 20;
            const skip = page * limit;
            const res = await dispatch(fetchDrivers({
                skip,
                limit,
                driverName: searchTermRef.current,
            })).unwrap();

            const newPosts = res?.data;

            if (newPosts?.length === 0) {
                setHasMore(false);
            } else {
                setPosts((prevPosts) => [...prevPosts, ...newPosts]);
            }
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [dispatch, page]);

    useEffect(() => {
        if (hasMore) {
            loadMorePosts();
        }
    }, [loadMorePosts, hasMore]);

    const lastPostElementRef = useCallback(
        (node) => {
            if (loading || !hasMore) return; 
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setPage((prevPage) => prevPage + 1);
                }
            });

            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const handleDriverDetails = () => {
        sendDataToParent(null);
    }

    const handleChangeTask = (event) => {
        const { name, value } = event.target;
        onValueChange(value);
        setSelectedValue(value);
        // handleDriverDetails()
    };

    return (
        <div className="next-drawer">
            <Box p={2} className='d-flex align-item-center jc-s-btwn gap-2 drawer-head'>
                <div className='d-flex flex-row gap-3'>
                    <Typography variant='h6' className='text-14 text-white-700'>Driverrr List</Typography>
                </div>
                <Typography style={{ cursor: "pointer" }} onClick={() => handleDriverDetails()} className='d-flex align-item-center gap-1 text-14 text-white-700'>
                    Close <EastIcon />
                </Typography>
            </Box>

            <Box className='drawer-scroll-sec'>
                <Box p={2} className='hd-drivers-listing'>
                    <List>
                        {posts?.map((data, idx) => (
                            <React.Fragment key={idx}>
                                <ListItem
                                    ref={posts.length === idx + 1 ? lastPostElementRef : null}
                                    className='d-flex align-item-top jc-s-btwn gap-2'
                                >
                                    <div className='d-flex flex-row gap-2'>
                                        <div className='d-flex radio-select'>
                                            <FormControl>
                                                <RadioGroup
                                                    value={selectedValue}
                                                    aria-labelledby=""
                                                    defaultValue="offDuty"
                                                    name="radio-buttons-group"
                                                    className='radio-colors'
                                                    onChange={handleChangeTask}
                                                >
                                                    <FormControlLabel className='m-0' value={data?._id} control={<Radio />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                        <div className='d-flex'>
                                            <img className='mt-1' src={driverPic} alt='' width={28} height={28} />
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <Typography variant='h6' className='text-14 text-white-700'>
                                                {data?.driverName}
                                            </Typography>
                                            <Typography className='text-12 text-black-200'>
                                                Eiusmod tempor
                                            </Typography>
                                            <Typography className='text-12 text-black-200 d-flex align-item-center gap-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                    <path d="M9.01364 0.945557C4.16495 0.945557 0.234322 4.87614 0.234322 9.72488C0.234322 12.1585 1.22475 14.3605 2.82413 15.9506L4.1337 14.6411C2.91855 13.4351 2.14988 11.7814 2.09167 9.94822H3.0906C3.28457 9.94822 3.44174 9.79108 3.44174 9.59708C3.44174 9.40322 3.28457 9.246 3.0906 9.246H2.10441C2.21054 7.6925 2.82837 6.28036 3.79134 5.17577L4.50918 5.89368C4.64627 6.03088 4.86867 6.03088 5.00576 5.89368C5.14296 5.75652 5.14296 5.53422 5.00576 5.3971L4.27885 4.67008C5.43798 3.58385 6.9696 2.8906 8.6625 2.80611V3.72317C8.6625 3.91703 8.81975 4.07421 9.01364 4.07421C9.20754 4.07421 9.36475 3.91703 9.36475 3.72317V2.80604C11.0812 2.89168 12.6318 3.60347 13.7965 4.71576L13.1152 5.39703C12.978 5.53415 12.978 5.75645 13.1152 5.89361C13.2524 6.03081 13.4747 6.03081 13.6118 5.89361L14.2795 5.22592C15.2176 6.32298 15.8184 7.7159 15.9229 9.24593H14.8388C14.6449 9.24593 14.4876 9.40314 14.4876 9.597C14.4876 9.79101 14.6449 9.94815 14.8388 9.94815H15.9355C15.8774 11.7813 15.1086 13.4351 13.8935 14.6411L15.203 15.9506C16.8025 14.3605 17.7929 12.1584 17.7929 9.7248C17.7929 4.87611 13.8623 0.945557 9.01364 0.945557Z" fill="#0163FF" />
                                                    <path d="M12.6848 6.24338C12.4255 6.02126 12.1113 6.37435 11.825 6.62621L8.28725 9.92816C8.07042 10.1598 8.12867 10.5668 8.41746 10.8372L8.47556 10.8914C8.76432 11.1617 9.17425 11.193 9.39115 10.9614L12.34 7.1733C12.6068 6.82378 12.9178 6.47346 12.7224 6.27917L12.6848 6.24338Z" fill="#0163FF" />
                                                </svg>
                                                3 mi
                                            </Typography>
                                        </div>
                                    </div>
                                    <Typography className='d-flex align-item-center gap-1 text-12'>
                                        <span className={data?.status ? 'dot-duty' : "dot-duty dot-off"}></span>
                                        <span className={data?.status ? 'driver-duty-txt' : 'driver-off-duty-txt'}>
                                            {data?.status ? "On duty" : "Off Duty"}
                                        </span>
                                    </Typography>

                                </ListItem>
                                {loading && <p>Loading...</p>}
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
            </Box>
        </div>
    );
};

export default MapDriveListDrawer;
