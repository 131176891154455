
// @mui material components
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography, Button, Menu } from "@mui/material";
import moment from "moment";
import RouteAnalyticsTable from "components/hyperdrive/route-analytics-table";
import { useEffect, useRef, useState } from "react";
import { fetchDriverName } from "../../../redux/slices/hyperdrive/drivers";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function RouteAnalytics() {
  const dispatch = useDispatch();
  const fromDateRef = useRef('');
  const toDateRef = useRef('');
  const [formData, setFormData] = useState({ startDate: '', endDate: "", driverName: "" });
  const { driverName = {}, loading: loading = false } = useSelector(state => state.drivers)

  // Date handlers
  const dateHandleFrom = (event) => {
    toDateRef.current = null;
    setFormData(prevState => ({
      ...prevState,
      endDate: ""
    }));
    const selectedDate = event.target.value;
    const formattedDate = moment(selectedDate).toISOString();
    fromDateRef.current = formattedDate;
    setFormData(prevState => ({
      ...prevState,
      startDate: formattedDate
    }));
    if (toDateRef.current && moment(formattedDate).isAfter(moment(toDateRef.current))) {
      toDateRef.current = null;
    }
  }

  // const dateHandleTo = (event) => {
  //   const selectedDate = event.target.value;
  //   const formattedDate = moment(selectedDate).toISOString();
  //   toDateRef.current = formattedDate;
  //   setFormData(prevState => ({
  //     ...prevState,
  //     endDate: formattedDate
  //   }));
  //   if (fromDateRef.current && moment(formattedDate).isBefore(moment(fromDateRef.current))) {
  //     event.target.value = '';
  //     toDateRef.current = null;
  //   } else {
  //     toDateRef.current = formattedDate;
  //   }
  // }
  const dateHandleTo = (event) => {
    const selectedDate = event.target.value;
    const formattedDate = moment(selectedDate).toISOString();
    if (fromDateRef.current) {
      const fromDateMoment = moment(fromDateRef.current);
      const toDateMoment = moment(formattedDate);
      const diffDays = toDateMoment.diff(fromDateMoment, 'days');

      if (diffDays > 5) {
        alert('The "To" date must be within 5 days of the "From" date.');
        event.target.value = '';
        toDateRef.current = null;
        return;
      }
    }
    toDateRef.current = formattedDate;
    setFormData(prevState => ({
      ...prevState,
      endDate: formattedDate
    }));

    if (fromDateRef.current && moment(formattedDate).isBefore(moment(fromDateRef.current))) {
      event.target.value = '';
      toDateRef.current = null;
    } else {
      toDateRef.current = formattedDate;
    }
  }

  const statusChangeHandler = (status) => {
    setFormData(prevState => ({
      ...prevState,
      driverName: status
    }));
  }

  useEffect(() => {
    dispatch(fetchDriverName())
  }, [])

  return (
    <Box p={{ xs: 2, sm: 3, lg: 4 }} className="h-100">
      <Stack direction='row' gap={2} alignItems='center' mb={2} className="report-bar">
        <Typography className="text-white-700" fontSize={14} fontWeight={600}>Report</Typography>
        <FormControl fullWidth sx={{ width: 220 }} className="dark-field">
          <InputLabel>All Drivers</InputLabel>
          <Select
            value={formData?.driverName}
            onChange={(e) => { statusChangeHandler(e.target.value) }}
            fullWidth
            className="form-select"
            label='All Drivers'
          >
            {
              driverName?.fleetDetails?.map((data, index) => {
                return (
                  <MenuItem key={index + 1} value={data}>{data}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>

        <Box className="filter-pro-status hd-date-field dark-field">
          <Stack direction='row' alignItems="center" gap={1}>
            <TextField
              type='datetime-local'
              name="tomorrow"
              label="From"
              InputLabelProps={{ shrink: true }}
              onChange={dateHandleFrom}
              value={formData?.startDate ? moment(formData?.startDate).format('YYYY-MM-DDTHH:mm') : ''}
              sx={{ width: 220 }}
            />
            <TextField
              type='datetime-local'
              name="tomorrow"
              label="To"
              onChange={dateHandleTo}
              InputLabelProps={{ shrink: true }}
              value={formData?.endDate ? moment(formData?.endDate).format('YYYY-MM-DDTHH:mm') : ''}
              sx={{ width: 220 }}
              disabled={!formData?.startDate}
            />
          </Stack>
        </Box>
      </Stack>
      <Box className="hd-wrapper">
        <RouteAnalyticsTable routeFilter={formData} />
      </Box>
    </Box>
  );
}

export default RouteAnalytics;
