import { Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AgGridTable } from 'components/tables/AgGridTable';
import { fetchRouteTable } from '../../../redux/slices/hyperdrive/drivers';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { formatTimestampToWithoutTime } from 'utilities/common';
import moment from 'moment';

export const RouteAnalyticsTable = ({ routeFilter }) => {
     const dispatch = useDispatch()
    const mainGridApiRef = useRef('')
    const pageRef = useRef(0)
    const gridRefApi = useRef(null)
    const { routeTable = {}, loading: loading = false } = useSelector(state => state.drivers)

    const getRows = useCallback(async (params, filters) => {
        const start = moment(filters?.startDate).format('YYYY-MM-DD')
        const end = moment(filters?.endDate).format('YYYY-MM-DD')
        gridRefApi.current = params;
        try {
            const res = await dispatch(fetchRouteTable({
                data: {
                    skip: pageRef.current,
                    limit: 20,
                    startDate: (filters?.startDate && filters?.endDate) && filters?.startDate ? start : "",
                    endDate: (filters?.startDate && filters?.endDate) && filters?.endDate ? end : "",
                    fleetName: filters?.driverName ? filters?.driverName : "",
                },
            })).unwrap();
            const { tasks } = res?.data;
            const { totalTasks } = res
            const driverListArray = tasks?.map((item) => ({
                ...item,
            }));
            params.successCallback([...driverListArray], totalTasks);
            pageRef.current += 20;
        } catch {
            params.successCallback([], 0);
        }
    }, [dispatch]);

    useEffect(() => {
        if (mainGridApiRef?.current) {
            if (loading) {
                mainGridApiRef.current.showLoadingOverlay();
            } else if (routeTable?.totalTasks === 0) {
                mainGridApiRef.current.showNoRowsOverlay();
            } else {
                mainGridApiRef.current.hideOverlay();
            }
        }
    }, [routeTable, loading]);

    useEffect(() => {
        if (routeFilter) {
            searchFn(routeFilter)
        }
    }, [routeFilter])

    const searchFn = useCallback((routeFilter) => {
        pageRef.current = 0
        const dataSource = {
            getRows: (params) => getRows(params, routeFilter)  // Pass status to getRows
        };
        if (mainGridApiRef.current) {
            mainGridApiRef.current.ensureIndexVisible(0, null);
            mainGridApiRef.current.setGridOption('datasource', dataSource);
        }
    }, [getRows]);

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Route Name',
            field: 'taskDisplayId',
            minWidth: 180
        },
        {
            headerName: 'Driver',
            field: 'fleet',
            cellRenderer: useCallback((props) => (
                props?.data?.fleet?.fleetName
            ), []),
            minWidth: 180
        },
        {
            headerName: 'Date',
            field: 'createdDate',
            cellRenderer: useCallback((props) => (
                props?.value ? formatTimestampToWithoutTime(props?.value) : "-"
            ), []),
        },
        {
            headerName: 'Vehicle Type',
            field: 'vehicle',
            cellRenderer: useCallback((props) => (
                props?.data?.vehicle?.name
            ), []),
        },
        {
            headerName: 'Planned Completion',
            field: 'plannedDuration',
            cellRenderer: useCallback((props) => (
                "--"
            ), []),
        },
        {
            headerName: 'Actual Completion',
            field: 'actualDuration',
            cellRenderer: useCallback((props) => (
                "--"
            ), []),
        },
        {
            headerName: 'Planned Duration',
            field: 'plannedDuration',
            cellRenderer: useCallback((props) => (
                props?.value?.hours ? `${props?.value?.hours} hours ${props?.value?.minutes} mins ${props?.value?.seconds} sec` : "--"
            ), []),
        },
        {
            headerName: 'Actual Duration',
            field: 'actualDuration',
            suppressMenu: false,
            cellRenderer: useCallback((props) => (
                props?.value?.hours ? `${props?.value?.hours} hours ${props?.value?.minutes} mins ${props?.value?.seconds} sec` : "--"
            ), []),
        },
        {
            headerName: 'Relative Difference',
            field: 'relativeDifference',
            suppressMenu: false,
            cellRenderer: (props) => {
                return <span className={props?.value?.sign === "-" ? "relativeDifferenceDark" : ""}> {props?.value?.hours ? `${props?.value?.hours} hours ${props?.value?.minutes} mins ${props?.value?.seconds} sec` : "--"}</span>
            },
            cellStyle: { textAlign: "right", justifyContent: "end" },
            // cellRenderer: useCallback((props) => (
            //     props?.value?.hours ? `${props?.value?.hours} hours ${props?.value?.minutes} mins ${props?.value?.seconds} sec` : "--"
            // ), []),
            headerClass: "right-aligned-cell-header",
        },

    ]);

    return (
        <>
            <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={0} mb={3}>
                <Grid item xs={12} sm={2}>
                    <Typography variant='h4' color='#ffffff'>Route Analytics</Typography>
                </Grid>

                {/* <Grid item xs={12} sm={10}>
                    <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={1.5}>
                        <TextField className="custom-search-field dark-field" placeholder="Search" label=""
                            // value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <IconButton size='small'>
                                        <SearchIcon width='18px' height='18px' />
                                    </IconButton>
                                </InputAdornment>,
                                endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                                    <IconButton onClick={() => searchHandler('')} size='small'>
                                        <CloseRounded />
                                    </IconButton>
                                </InputAdornment> : null
                            }}
                        />

                        <MDButton style={{ textTransform: "none", minWidth: "180px", borderRadius: "30px" }}
                            startIcon={<FileDownloadOutlinedIcon fontSize='large' />}
                            variant="contained" color="primary"
                        >
                            Export as .CSV
                        </MDButton>
                    </Stack>
                </Grid> */}
            </Grid>

            <div className="ag-theme-quartz-dark">
                <AgGridTable
                    col={columnDefs}
                    mainGridApiRef={mainGridApiRef}
                    getRows={getRows}
                    pageRef={pageRef}
                    isLoading={loading}
                    style={{ height: "64vh" }}
                    gridOptions={{ rowHeight: 64 }}
                    maxLimit={20}
                />
            </div>
        </>
    )
}

export default RouteAnalyticsTable;
